import React from 'react';
import cx from './survey.module.scss';

function Survey() {
  return (
    <div>Survey</div>
  )
}

export default Survey
