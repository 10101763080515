import { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Modal, Tooltip, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import { changeCreateCohortModalState, changeSubmittedReportModalState, changeTeamSubmittedReportModalState } from 'src/store/common/common.slice';
import { useGetPlaces } from 'src/hooks';

import './cohortSubmitReport.scss';
import cohortSubmitReportValidationSchema from './cohortSubmitReportValidationSchema';
import { useNavigate } from 'react-router-dom';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { DownloadIcon, FileIcon2, UploadIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { Col, Row } from 'react-bootstrap';

export type TCreateCohortValues = {
    name: string,
    startDate: Date | string,
    endDate: Date | string,
    programObjective?: string
};

const SubmittedTeamReportSummeryModal = () => {
    const loading: boolean = useAppSelector<boolean>((store: any) => store.common.loading);
    const isModalOpen: boolean = useAppSelector<boolean>((store: any) => store.common.isTeamSubmittedReportModal);
    const navigate = useNavigate()

    // Hooks
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(changeTeamSubmittedReportModalState(false));
    };
    const dragDropRef = useRef<HTMLDivElement>(null);
    const uploadImageRef = useRef<HTMLInputElement>(null);

    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };

    return (
        <Modal
            isModalOpen={isModalOpen}
            onModalClose={closeModal}
            title="Submitted Report"
            className="submitted-report-modal"
        >
            <div className="submitted-report-body">
                <div className='submitted-report-submit-box'>
                    <div className='inputFields'>
                        <InputField
                            label="Upload resource"
                            id='banner-label'
                            hidden
                            variant={componentSizeVariantsEnum.SMALL}
                            placeholder="Program Banner"
                            type="file"
                        />

                        <div
                            className="dynamic-field__upload-file"
                            draggable
                            ref={dragDropRef}
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <span className="dynamic-field__upload-file__drag-drop" onClick={openImageFileManager}>
                                <UploadIcon />
                                <input
                                    type="file"
                                    hidden
                                    value={''}
                                    ref={uploadImageRef}
                                    accept=".jpg,.jpeg,.png,"
                                    className="select-post-image"
                                />
                            </span>
                            {/* <Typography as="p" variant="body-2" fontWeight="regular">
                      or
                    </Typography> */}
                            <Typography
                                as="span"
                                variant="body-2"
                                fontWeight="medium"
                                className="dynamic-field__upload-file__browse"
                                onClick={openImageFileManager}
                            >
                                Browse on your device
                            </Typography>

                        </div>

                    </div>
                </div>
                <ul className={'submitted-report-list'}>
                    <li className={'submitted-report-list-item'}>
                        <Row>
                            <Col md={5}>
                                <div className="submitted-report-item">
                                    <div className="program-file-card">
                                        <div className="program-file-card__file">{<FileIcon2 />}</div>
                                        <div className="program-file-card__text">
                                            <Tooltip text={'fileName'}>
                                                <Typography
                                                    as="p"
                                                    variant="caption"
                                                    fontWeight="semiBold"
                                                    style={{ color: colors.neutral[1000] }}
                                                >
                                                    {'demo.pdf'}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                        <div className="program-file-card__actions">
                                            <DownloadIcon />

                                            {/* {<button
                                        type="button"
                                        className="remove-button"
                                        onClick={(e: any) => {
                                            e?.preventDefault()
                                            e?.stopPropagation()
                                        }}
                                        aria-label="Remove file"
                                    >
                                        X
                                    </button>} */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={10} xs={10}>
                                        <div className='submitted-report-item-timestamp'>
                                            <span className='date-time'>24-december, 2024 10:20</span>
                                        </div>
                                    </Col>
                                    <Col md={2} xs={2}>
                                        <div className='submitted-report-item-removebox'>
                                            <button
                                                type="button"
                                                className="remove-button"
                                                onClick={(e: any) => {
                                                    e?.preventDefault()
                                                    e?.stopPropagation()
                                                }}
                                                aria-label="Remove file"
                                            >
                                                X
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </li>

                </ul>
            </div >
        </Modal >
    );
};

export default SubmittedTeamReportSummeryModal;
