/* eslint-disable multiline-ternary */
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetProgramApplication, TGetProgramData, TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { formatDate } from 'src/utils';
import "bootstrap/dist/css/bootstrap.css";
import { getProgramDocument } from 'src/store/program/program.slice';
import './programDetails.scss';
import FileCard from '../../fileCard/FileCard';
import Button from 'src/components/button/Button';
import { useContext, useState } from 'react';
import { ViewApplicationSelf } from 'src/pages/viewMyApplication/ViewMyApplication';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import { useToggleOpenModal } from 'src/hooks';

import { cancelCreateProgramModalId } from 'src/pages/createProgram/CreateProgram';
interface ProgramDetailsProps {
  programDetailsValues: any; // You can define a proper type later
  handleNext: () => void;
}
const ProgramDetailsPreview = ({ programDetailsValues, handleNext }: ProgramDetailsProps) => {
  console.log(programDetailsValues, "programDetasilsValues")
  const toggleOpenModal = useToggleOpenModal();
  // Hooks
  const handleToggleOpenCancelCreateProgramModal = () => {
    toggleOpenModal({ modalId: cancelCreateProgramModalId });
  };
  const viewProgram: any = programDetailsValues;
  const programDetailsFields: { label: string; value: string }[] = [
    { label: 'Program Name', value: viewProgram?.title },
    { label: 'Start date', value: formatDate({ dateString: viewProgram?.startDate }) },
    { label: 'End date', value: formatDate({ dateString: viewProgram?.endDate }) }
  ]; 
  programDetailsFields.push({ label: 'Banner', value: viewProgram?.banner?.url })
  
  const dispatch = useAppDispatch();
  const [applicationShow, setApplicationShow] = useState<boolean>(false)
  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocument(payload));
  };
  const isUserViewingApplication = useContext(ViewApplicationSelf);
  // let programDetailsFields: { label: string; value: string }[] = [
  //   { label: 'Program Name', value: viewProgram?.title },
  //   { label: 'Start date', value: formatDate({ dateString: viewProgram?.startDate }) },
  //   { label: 'End date', value: formatDate({ dateString: viewProgram?.endDate }) }
  // ];

  // if (type !== 'application' && viewProgram?.banner?.url) {
    // programDetailsFields.push({ label: 'Banner', value: viewProgram?.banner?.url })
  // } else {
  //   programDetailsFields = [...programDetailsFields]
  // }

  function convertTo12HourFormat(time: string): string {
    if (time) {
      const timeArray: string[] = time.split(':');
      const [hour, minute]: [string, string] = [timeArray[0] || '', timeArray[1] || ''];
      let hourNum: number = parseInt(hour);
      const suffix: string = hourNum >= 12 ? 'PM' : 'AM';

      hourNum = hourNum % 12 || 12;

      return `${hourNum}:${minute} ${suffix}`;
    } else {
      return '00:00'
    }
  }

  return (
    <div className="view-program-program-details position-relative">
      {/* {type !== 'application' && !isUserViewingApplication && <Button
        size="small"
        variant="primary"
        className="program-actions__actions__next"
        style={{ position: 'absolute', top: '0', right: '0', width: '180px' }}
        type="button"
        onClick={() => setApplicationShow(!applicationShow)}
      >
        {applicationShow ? "Hide Application Form" : "View Application Form"}
      </Button>} */}
      {/* {applicationShow ? (
        <CreateProgramReview
          key="create-program-review"
          handleSave={() => { }}
          applicationFormSections={viewProgram?.applicationForm?.sections}
          createProgramStep={3}
          type='viewApplication'
        />
      ) : <> */}
        {/* <Form className="custom-application-form__form"> */}
              {/* {' '} */}
          
              {/* Sections end */}
            {/* </Form> */}
       <div className="program-details__form__header">
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Program Details
                </Typography>
                <div className="program-details__form__header__navigation">
                  <Button
                    size="extraSmall"
                    variant="secondary"
                    type="button"
                    onClick={handleToggleOpenCancelCreateProgramModal}
                  >
                    Discard & Exit
                  </Button>
                  <Button size="extraSmall" variant="primary" type="button" onClick={handleNext}>
                    Continue
                  </Button>
                </div>
              </div>
        {programDetailsFields.map(({ label, value }, index) => {
          if (label === 'Banner') {
            return (
              <div key={index} className="view-program-program-details__field">
                <Typography
                  className="view-program-program-details__field__label"
                  as="p"
                  variant="body-2"
                  fontWeight="semiBold"
                >
                  {label}
                </Typography>
                <div style={{ borderRadius: '0px', overflow: 'hidden' }}>
                  <img src={value} alt="banner" style={{ height: '150px', width: '600px', backgroundPosition: 'center', objectFit: 'cover' }} />
                </div>
              </div>
            )
          }
          return (
            <div key={index} className="view-program-program-details__field">
              {label && (
                <Typography
                  className="view-program-program-details__field__label"
                  as="p"
                  variant="body-2"
                  fontWeight="semiBold"
                >
                  {label}
                </Typography>
              )}
              <Typography
                className="view-program-program-details__field__value"
                as="p"
                variant="caption"
                fontWeight="regular"
              >
                {value}
              </Typography>
            </div>
          )
        })}
        {viewProgram?.programDetails?.map(({ label, values, type, files }, index) => (
          <div key={index} className="view-program-program-details__field">
            <Typography
              className="view-program-program-details__field__label"
              as="p"
              variant="body-2"
              fontWeight="semiBold"
            >
              {label}
            </Typography>
            {type === 'fileUpload' && files && files?.length > 0 && (
              <div className="view-program-program-details__field__files">
                {files.map((file) => {
                  return (
                    <FileCard
                      fileName={file?.fileName}
                      fileSize={file?.fileSize}
                      key={file?.key}
                      type='preview'
                      onClick={() => {
                        getDocument({ key: file?.key });
                      }} removeFile={undefined} />
                  );
                })}
              </div>
            )}
            {type !== 'fileUpload' && (
              <Typography
                className="view-program-program-details__field__value"
                as="p"
                variant="caption"
                fontWeight="regular"
              >
                {type === 'dateSelection' ? (
                  formatDate({ dateString: values })
                ) : type === 'timeSelection' ? (
                  convertTo12HourFormat(values)
                ) : (
                  <div
                    className="view-program-program-details__field__value_editor"
                    dangerouslySetInnerHTML={{ __html: values }}
                  />
                )}
              </Typography>
            )}
          </div>
        ))}
      {/* </>} */}
    </div>
  );
};

export default ProgramDetailsPreview;
