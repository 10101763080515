import React, { useRef, useState } from 'react';
import { Col, Row, Tooltip } from 'react-bootstrap';
import { componentSizeVariantsEnum } from 'src/constants';
import { InputField } from 'src/components/common/formFields';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import db from '../../CohortSidebarComponent/Dashboard/dashboard.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import cx from './cohortRelationship.module.scss';

import OptionsField from '../../applyToProgram/previewField/OptionsField';
import { MdAdd } from 'react-icons/md';
import { TActionButton } from '../../viewCohort/viewCohort.types';
import { UploadIcon } from 'src/components/common/common.icons';
import SubmittedReportSummeryModal from './SubmittedReport';
import { useDispatch } from 'react-redux';
import { changeSubmittedReportModalState } from 'src/store/common/common.slice';
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts';

interface TOnboardProps {
    handleBack: () => void;
    handleClick: (id: string) => void;
}
export default function CohortRelationship({ handleBack, handleClick }: TOnboardProps) {
    const dispatch = useDispatch();
    const actionButtons: TActionButton[] = [
        { text: 'Teams', id: "Teams", onClick: handleAction },
        { text: 'Mentor', id: "Mentor", onClick: handleAction },
        { text: 'Generate Report', id: "Generate Report", onClick: handleAction },
        { text: 'Submitted Report', id: "Submitted Report", onClick: handleAction }
    ];
    const dragDropRef = useRef<HTMLDivElement>(null);
    const uploadImageRef = useRef<HTMLInputElement>(null);

    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };
    function handleAction(type: string) {
        if (type === 'Submitted Report') {
            dispatch(changeSubmittedReportModalState(true))
        } else {
            handleClick(type)
        }
    }

    const data = [
        {
            date: '05-feb',
            Total_Allotment: 4000,
            Total_Expense: 2400
        },
        {
            date: '06-feb',
            Total_Allotment: 4000,
            Total_Expense: 5000
        },
        {
            date: '07-feb',
            Total_Allotment: 9000,
            Total_Expense: 2400
        },
        {
            date: '08-feb',
            Total_Allotment: 4000,
            Total_Expense: 3600
        },
        {
            date: '09-feb',
            Total_Allotment: 7000,
            Total_Expense: 5000
        },
        {
            date: '10-feb',
            Total_Allotment: 2000,
            Total_Expense: 3900
        },
        {
            date: '11-feb',
            Total_Allotment: 2000,
            Total_Expense: 2200
        },
        {
            date: '12-feb',
            Total_Allotment: 1000,
            Total_Expense: 3100
        }
    ];

    return (
        <>
            <SubmittedReportSummeryModal />
            <div className={`${cx.relationship}`}>
                <div className={`${cx.titleHeader}`}>
                    <h5 className={`${cx.title}`}>Team - X</h5>
                    <Button
                        size="small"
                        variant="primary"
                        className="program-actions__actions__next"
                        style={{ width: 'auto' }}
                        type="button"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                </div>
                <div className='insideCard'>
                    <Row>
                        <Col md={12} style={{ marginBottom: '8px' }}>
                            <Row>
                                <Col md={12}>
                                    <div className={cx.actionButtons}>
                                        {actionButtons?.map((button: TActionButton) => {
                                            return (
                                                <Button
                                                    size="small"
                                                    variant="primary"
                                                    className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                                                    type="button"
                                                    key={button.id}
                                                    onClick={() => button.onClick(button.text)}
                                                >
                                                    {/* <span className={cx.addIcon}>
                                                        <MdAdd />
                                                    </span> */}
                                                    <span>{button.text} </span>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                </Col>
                                {/* <Col md={3}>
                                <div className={`${cx.percentage}`}>
                                    <span>Percentage completion</span>
                                    <span className={`${cx.highlight}`}>70%</span>
                                </div>
                            </Col> */}
                                <Col md={12}>
                                    <Row>
                                        <Col md={2}></Col>
                                        <Col md={4}>
                                            <div className={`${cx.fundAction}`}>
                                                <h5 className={`${cx.actionHeading}`}>Fund Alloted</h5>
                                                <InputField
                                                    required
                                                    label=""
                                                    style={{ width: '100px', padding: '10px' }}
                                                    id="fundAllocated"
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    placeholder="Fund Alloted"
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className={`${cx.fundAction}`}>
                                                <h5 className={`${cx.actionHeading}`}>Expense</h5>
                                                <InputField
                                                    required
                                                    label=""
                                                    style={{ width: '100px', textAlign: 'center' }}
                                                    id="expense"
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    placeholder="Expense"
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <div className={`${cx.details}`}>
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'T'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"

                                                                >
                                                                    {'Taran adarsh'}
                                                                </Typography>

                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'T'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"

                                                                >
                                                                    {'Taran adarsh'}
                                                                </Typography>

                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'T'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"

                                                                >
                                                                    {'Taran adarsh'}
                                                                </Typography>

                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'M2'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"
                                                                >
                                                                    Mentor 1
                                                                </Typography>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'M1'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"
                                                                >
                                                                    Mentor 2
                                                                </Typography>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'M1'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"
                                                                >
                                                                    Mentor 2
                                                                </Typography>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                                <Row className='mt-3'>
                                    <Col md={12}>
                                        <div className='insideCard'>
                                            <ul className={cx.milestonelist}>
                                                <li>
                                                    <div className={'milestoneBox'}>
                                                        <span className={cx.mileStoneHeading}>Milestone 1</span>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="completion"
                                                                style={{ padding: '5px' }}
                                                                className='milestonePercentageCompletion'
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="% completion"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="comments"
                                                                style={{ padding: '5px', textAlign: 'center' }}
                                                                className={`${cx.comments}`}
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="Comments"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <ul className={cx.nestedList}>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><hr /></li>
                                                <li>
                                                    <div className={'milestoneBox'}>
                                                        <span className={cx.mileStoneHeading}>Milestone 2</span>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="completion"
                                                                style={{ padding: '5px' }}
                                                                className='milestonePercentageCompletion'
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="% completion"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="comments"
                                                                style={{ padding: '5px', textAlign: 'center' }}
                                                                className={`${cx.comments}`}
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="Comments"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <ul className={cx.nestedList}>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className={`${db.chartContainer}`}>
                                            <BarChart
                                                width={700}
                                                height={300}
                                                data={data}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                {/* <XAxis
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    height={1}
                                    scale="band"
                                    xAxisId="quarter"
                                /> */}
                                                <YAxis />
                                                <Tooltip />
                                                <Legend content={<CustomLegend payload={[{ color: 'red' }]} />} />
                                                <Bar dataKey="Total_Expense" fill="#fa4616" />
                                                <Bar dataKey="Total_Allotment" fill="#ffe5d5" />
                                            </BarChart>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div >
            </div>
        </>
    )
}

const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
        <ul className={db.legendList}>
            {payload.map((entry: any, index: any) => (
                <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 10, height: 10, backgroundColor: entry.color, marginRight: 5 }} />
                    {/* {entry.value}: {entry.payload[entry.dataKey]} */}
                    {entry.value}: 2000
                </li>
            ))}
        </ul>
    );
} 
