import { useEffect, useRef, useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import Button from 'src/components/button/Button';
import { Card, Loader, Separator, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { StorageUtils } from '../../../../../utils';
import { CalendarIcon, PlusIcon, UploadIcon } from 'src/components/common/common.icons';
import { useGetFormikField, useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';
import { getUuid } from 'src/utils';
import { cancelCreateProgramModalId } from 'src/pages/createProgram/CreateProgram';

import './programDetails.scss';
import {
  TCustomField,
  TFieldType,
  TProgramDetailsField,
  TProgramDetailsInitialValues,
  TProgramDetailsProps,
  TProgramDetailsRequestPayload
} from './programDetails.types';
import AddEditProgramDetailsFields from './addEditProgramDetailsFields/AddEditProgramDetailsFields';
import DynamicField from './dynamicField/DynamicField';
import { defaultErrors } from './programDetails.data';
import createProgramDetailsSchema from './createProgramDetailsSchema';
import validateForm from './validateForm';
import useUpdateErrors from './hooks/useUpdateErrors';
import { Col, Row } from 'react-bootstrap';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';
import { CompanyAPIService } from 'src/services/company.service';
import { TGetCompany, TGetCompanyPresignedUrlActionRequest, TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import { APIResponse } from 'src/services/base.api.service';

const addProgramDetailsFieldsId = 'addProgramDetailsFields';
const editProgramDetailsFieldsId = 'editProgramDetailsFields';
const confirmDeleteFieldModalId = 'confirmDeleteFieldModal';

const ProgramDetails = ({
  shortlistStages,
  updateShortlistStages,
  customFields,
  setCustomFields,
  setProgramDraft,
  programDetailsFormikValues,
  continueToCustomApplication
}: TProgramDetailsProps) => {
  // Hooks
  const dispatch = useDispatch()
  const modalId: string = useAppSelector((store: any) => store.common.modalId);
  const viewCompany: TGetCompany = useAppSelector((store) => store.company.myCompany);
  const toggleOpenModal = useToggleOpenModal();
  const { paramValue } = useUrlParamValue();
  const dragDropRef = useRef<HTMLDivElement>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const programListParams = useMemo(() => {
    const programTitle: any = (paramValue({ paramName: 'Draft' }))
    // const filterProgram: TFilterProgram = (paramValue({ paramName: 'filterProgram' }) ||
    //   'All') as TFilterProgram;
    return { programTitle };
  }, [window.location.search]);
  // State
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });
  const startDateRef = useRef<HTMLInputElement[]>([]);
  const endDateRef = useRef<HTMLInputElement[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>(defaultErrors);
  const [showErrorsOnChange, setShowErrorsOnChange] = useState<boolean>(false);
  const [bannerLoading, setBannerLoading] = useState<boolean>(false)
  const updateErrors = ({ errors }: { errors: Record<string, string> }) => {
    setErrors(errors);
  };

  useEffect(() => {
    if (programDetailsFormikValues) {
      setInitialValues(programDetailsFormikValues)
      // setCustomFields(Object.values(programDetailsFormikValues)?.filter((item:any) => item?.files))
    }
  }, [programDetailsFormikValues])

  // Constants
  const isAddProgramDetailsFieldsOpen = modalId === addProgramDetailsFieldsId;
  const isEditProgramDetailsFieldsOpen = modalId === editProgramDetailsFieldsId;

  // Update errors hook
  const updateErrorsHook = useUpdateErrors();

  const updateErrorsOnChange = ({ values }: { values: TProgramDetailsInitialValues }) => {
    updateErrorsHook({ values, customFields, updateErrorsState: updateErrors });
  };

  const [initialValues, setInitialValues] = useState<TProgramDetailsInitialValues>({
    title: '',
    startDate: '',
    endDate: '',
    banner: {
      url: ''
      // height: '',
      // width: ''
    },
    stages: NaN,
    stageTitles: [],
    stageNotification: []
  });
  useEffect(() => {
    const { programTitle } = programListParams;
    const storedData = localStorage.getItem('programDetailsFormValues');
    if (storedData) {
      const programDetails = JSON.parse(storedData);
      setInitialValues(programDetails.ProgramDraft);
      setCustomFields(programDetails.programDetails)
      StorageUtils.remove('programDetailsFormValues')
    } else {
      console.log('No data found in localStorage');
    }
  }, []);
  
  let shortListStagesArray: any[];

  try {
    shortListStagesArray = [...new Array(shortlistStages).keys()];
  } catch {
    shortListStagesArray = [];
  }

  const handleToggleOpenAddFieldsModal = () => {
    toggleOpenModal({ modalId: addProgramDetailsFieldsId });
  };

  const handleToggleOpenEditFieldsModal = () => {
    toggleOpenModal({ modalId: editProgramDetailsFieldsId });
  };

  const handleToggleOpenDeleteFieldModal = () => {
    toggleOpenModal({ modalId: confirmDeleteFieldModalId });
  };

  const handleToggleOpenCancelCreateProgramModal = () => {
    toggleOpenModal({ modalId: cancelCreateProgramModalId });
  };

  const openChangeFieldModal = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setEditFieldModalData({ fieldType, id: id || '' });
    handleToggleOpenEditFieldsModal();
  };

  const changeFieldType = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setCustomFields((prev) => {
      const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
      const selectedField = currentFields.find((field) => field.id === id);

      if (selectedField) {
        selectedField.type = fieldType;
      }

      return currentFields;
    });
    setEditFieldModalData({ id: '', fieldType: 'label' });
    handleToggleOpenEditFieldsModal();
  };

  const handleAddField = ({ fieldType }: { fieldType: TFieldType }) => {
    handleToggleOpenAddFieldsModal();

    const newId = getUuid();

    const newField: TCustomField = {
      id: newId,
      type: fieldType,
      label: '',
      placeholder: '',
      values: false,
      files: []
    };
    setCustomFields((prev) => prev.concat(newField));
  };

  const handleDeleteField = ({ id }: { id: string }) => {
    if (id) {
      setCustomFields((prev) => {
        const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
        const selectedFieldIndex: number = currentFields.findIndex((field) => field.id === id);

        if (selectedFieldIndex > -1) {
          currentFields.splice(selectedFieldIndex, 1);
        }

        return currentFields;
      });

      handleToggleOpenDeleteFieldModal();
    }
  };

  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  function handleExtension(fileName: string) {
    if (!fileName) {
      return ''
    }
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')); // Get the file extension, e.g., ".jpg"
    const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.')); // Get the file name without the extension
    // Add the timestamp before the file extension

    const timestamp = new Date().getTime();
    return `${fileNameWithoutExtension}-${timestamp}${fileExtension}`;
  }

  const handleUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    setBannerLoading(true)
    if (file) {
      const fileName = handleExtension(file?.name)
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        try {
          const data = reader.result;
          const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
            id: viewCompany?.id,
            data,
            showPopup: false
          };
          const response = await httpRequest(`program/banner/upload?companyId=${uploadProfilePicturePayload.id}&fileName=${fileName}`, 'GET', null, 'json', 'program');
          if (response?.data?.signedUrl) {
            const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
              await new CompanyAPIService().uploadCompanyProfilePicture({
                presignedUrl: response?.data?.signedUrl,
                data,
                includeAuthorizationHeaders: false
              });
            if (res.status === 200) {
              const data = await httpRequest(`program/banner/upload?companyId=${uploadProfilePicturePayload.id}&fileName=${fileName}`, 'GET', null, 'json', 'program');
              if (data?.data?.key) {
                // setFieldValue('banner', { url: data?.data?.key, height: '500px', width: '100%' })
                setFieldValue('banner', { url: data?.data?.key })
              } else {
                dispatch(
                  openPopup({
                    popupMessage: 'something went wrong',
                    popupType: 'error'
                  })
                );
              }
              setBannerLoading(false)
            } else {
              dispatch(
                openPopup({
                  popupMessage: res?.message || 'something went wrong',
                  popupType: 'error'
                })
              );
            }
          } else {
            setBannerLoading(false)
            dispatch(
              openPopup({
                popupMessage: 'something went wrong',
                popupType: 'error'
              })
            );
          }
        } catch (error) {
          setBannerLoading(false)
          dispatch(
            openPopup({
              popupMessage: error?.message || 'something went wrong',
              popupType: 'error'
            })
          );
        }
      }
    }
  }

  const handleSubmit = async (values: TProgramDetailsInitialValues) => {
    setShowErrorsOnChange(true);

    const { title, startDate, endDate, stages, stageTitles, stageNotification, banner, ...programDetailsFields } = values;
    setProgramDraft(values)
    // localStorage.setItem('programDetailsFormValues', JSON.stringify(values));
    const programDetails: any[] = [];

    try {
      Object.keys(programDetailsFields).forEach((key) => {
        const field = programDetailsFields[key as keyof typeof programDetailsFields];
        programDetails.push(field);
      });
    } catch { }

    const programDetailsValues: TProgramDetailsRequestPayload = {
      title,
      startDate,
      endDate,
      stages,
      banner,
      stageTitles,
      stageNotification,
      programDetails
    };

    const customFieldValues: Record<string, string> = {};

    Object.keys(programDetailsFields).forEach((fieldKey) => {
      const field: any =
        programDetailsFields[fieldKey as keyof typeof programDetailsFields];
      const id: string = field?.id;

      customFieldValues[id] = field?.values || '';

      if (field?.type !== 'label') {
        customFieldValues[`${id}-label`] = field?.label || '';
      }
    });

    const schema = createProgramDetailsSchema({ fields: customFields });

    const errors = await validateForm({
      values: { title, endDate, stages, banner, ...customFieldValues },
      schema,
      updateErrors
    });
    console.log(errors, "errorr")

    const hasZeroErrors: boolean = errors?.length < 1;

    if (hasZeroErrors) {
      continueToCustomApplication({ values: programDetailsValues, formikValues: values });
    }
  };
  console.log(customFields, 'customFields');

  return (
    <div className="program-details">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, setFieldValue }) => {
          // Update errors on change of values
          // only after continue button is clicker (showErrorsOnChange)
          useEffect(() => {
            if (showErrorsOnChange) {
              updateErrorsOnChange({ values });
            }
          }, [values]);
          return (
            <Form className="program-details__form">
              <div className="program-details__form__header">
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Program Details
                </Typography>
                <div className="program-details__form__header__navigation">
                  <Button
                    size="extraSmall"
                    variant="secondary"
                    type="button"
                    onClick={handleToggleOpenCancelCreateProgramModal}
                  >
                    Discard & Exit
                  </Button>
                  <Button size="extraSmall" variant="primary" type="submit">
                    Continue
                  </Button>
                </div>
              </div>
              <Typography as="p" variant="body-2" fontWeight="regular">
                We&apos;ve simplified your program details entry! Begin with our suggested fields
                for a quick and easy way to enter information. Feel free to customize it according
                to your needs.
              </Typography>
              <Card>
                <InputField
                  label="Enter Program Title"
                  id="title"
                  value={values?.title}
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Program Title"
                  type="text"
                  required
                  errorMessage={errors?.title}
                />
              </Card>
              <div className="program-details__form__row">
                {/* <Card className="program-details__form__row__field">
                  <InputField
                    label="Select start date"
                    id="startDate"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Select date"
                    type="date"
                    inputRef={(el: HTMLInputElement) => {
                      startDateRef.current[0] = el;
                    }}
                    endIconClickHandler={() => {
                      if (startDateRef.current) {
                        startDateRef.current[0].showPicker();
                      }
                    }}
                    onChange={(e) => {
                      setFieldValue('startDate', e.target.value);
                    }}
                    endIcon={<CalendarIcon />}
                    required
                    errorMessage={errors?.startDate}
                  />
                </Card> */}
                <Card className="program-details__form__row__field">
                  <InputField
                    label="Select end date"
                    id="endDate"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Select date"
                    type="date"
                    inputRef={(el: HTMLInputElement) => {
                      endDateRef.current[0] = el;
                    }}
                    endIconClickHandler={() => {
                      if (endDateRef.current) {
                        endDateRef.current[0].showPicker();
                      }
                    }}
                    onChange={(e) => {
                      setFieldValue('endDate', e.target.value);
                    }}
                    endIcon={<CalendarIcon />}
                    required
                    errorMessage={errors?.endDate}
                  />
                </Card>
              </div>
              <div className="program-details__form__row">
                <Card className="program-details__form__row__field">
                  {/* <div className="dynamic-field__header">
                      <Typography as="span" variant="caption" fontWeight="semiBold">
                        Upload file field
                      </Typography>
                      <div className="dynamic-field__header__actions">
                        <ActionIcon
                          onClick={() => {
                            openChangeFieldModal({ id, fieldType: type });
                          }}
                        >
                          <EditIcon />
                        </ActionIcon>
                        <ActionIcon onClick={handleDeleteField}>
                          <TrashIcon />
                        </ActionIcon>
                      </div>
                    </div> */}
                  {!bannerLoading && <>
                    <InputField
                      label="Program Banner : recommended size - 396 px (Height) x 1584 px (Width)"
                      id='banner-label'
                      hidden
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Program Banner"
                      type="text"
                    />

                    <div
                      className="dynamic-field__upload-file"
                      draggable
                      ref={dragDropRef}
                      onDrop={(e) => {
                        e.preventDefault();
                        handleUpload(e, setFieldValue)
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <span className="dynamic-field__upload-file__drag-drop" onClick={openImageFileManager}>
                        <UploadIcon />
                        {/* <Typography as="p" variant="body-2" fontWeight="regular">
                        Drag and drop your banner
                      </Typography> */}
                        <input
                          type="file"
                          hidden
                          value={''}
                          ref={uploadImageRef}
                          accept=".jpg,.jpeg,.png,"
                          className="select-post-image"
                          onChange={(e) => {
                            handleUpload(e, setFieldValue)
                          }}
                        // onChange={(e) => {
                        //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                        // }}
                        />
                      </span>
                      {/* <Typography as="p" variant="body-2" fontWeight="regular">
                      or
                    </Typography> */}
                      <Typography
                        as="span"
                        variant="body-2"
                        fontWeight="medium"
                        className="dynamic-field__upload-file__browse"
                        onClick={openImageFileManager}
                      >
                        Browse on your device
                      </Typography>

                    </div>
                  </>}
                  {bannerLoading && <Loader />}
                  {/* {<ErrorMessage message={errors?.[id]} />} */}
                  {/* <Row className='mt-2'>
                    <Col md={2}>
                      <InputField
                        label='Maximum Width'
                        id="max-width"
                        value={values?.banner?.width}
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Ex. 100(in %)"
                        type="number"
                        onChange={(e) => {
                          if (+e.target?.value < 0 || +e.target?.value > 100) {
                            return
                          }
                          setFieldValue('banner', { ...values?.banner, width: e.target.value });
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <InputField
                        label='Maximum Height'
                        id="max-height"
                        value={values?.banner?.height}
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Ex. 100(in px)"
                        type="number"
                        onChange={(e) => {
                          if (+e.target?.value < 0) {
                            return
                          }
                          setFieldValue('banner', { ...values?.banner, height: e.target.value });
                        }}
                      />
                    </Col>
                  </Row> */}
                  {values?.banner?.url && !bannerLoading && <Row>
                    <Col md={12}>
                      <div className='position-relative'>
                        <button style={{ position: 'absolute', top: '10px', right: '10px', background: '#fff', height: '23px', fontSize: '14px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: 'none', color: 'red' }} onClick={() => setFieldValue('banner', { url: '' })}>
                          X
                        </button>
                        <img src={values?.banner?.url} alt='banner' style={{ width: '600px', height: '150px', objectFit: 'cover', backgroundPosition: 'center', marginTop: '10px', borderRadius: '10px' }} />
                      </div>
                    </Col>
                  </Row>}
                </Card>
              </div>

              {/* Shortlist stages */}
              <Card className="program-details__form__stages">
                <InputField
                  label={
                    <>
                      {' '}
                      <Typography as="span" variant="caption" fontWeight="semiBold">
                        Specify the number of shortlist stages desired for the shortlisting
                        applications.
                      </Typography>
                      {'  '}
                      <Typography as="span" variant="caption" fontWeight="regular">
                        (Max: 5 stages)
                      </Typography>
                    </>
                  }
                  id="stages"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Ex. 4"
                  type="number"
                  onChange={(e) => {
                    const value = parseInt(e.target.value);

                    if (value <= 5 && value >= 0) {
                      updateShortlistStages({ value });
                      setFieldValue('stages', value);
                    } else {
                      updateShortlistStages({ value: NaN });
                      setFieldValue('stages', NaN);
                    }
                  }}
                  required
                  errorMessage={errors?.stages}
                />
                {shortListStagesArray?.length > 0 && <Separator />}
                {shortListStagesArray.map((num) => {
                  const stageNumber: string = num + 1;

                  return (
                    <div key={num}>
                      <InputField
                        key={num}
                        label={`Stage ${stageNumber}`}
                        id={`stage${stageNumber}`}
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Stage name"
                        type="text"
                        value={values.stageTitles?.[num]}
                        onChange={(e) => {
                          const currentFields: string[] = JSON.parse(
                            JSON.stringify(values?.stageTitles)
                          );
                          currentFields[num] = e.target.value;
                          setFieldValue('stageTitles', currentFields);
                        }}
                      />
                      {<div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                        <label htmlFor="push-notification" style={{ fontSize: '12.8px', fontWeight: '500' }}>Enable Notification</label>
                        <input
                          type="checkbox"
                          id='push-notification'
                          checked={values.stageNotification?.[num]}
                          onChange={(e) => {
                            const currentFields: boolean[] = JSON.parse(
                              JSON.stringify(values?.stageNotification)
                            );
                            currentFields[num] = e.target.checked;
                            setFieldValue('stageNotification', currentFields);
                          }}
                        />
                      </div>}
                    </div>
                  );
                })}
              </Card>

              {/* Custom fields */}
              {customFields.map((field: TCustomField, index) => {
                return (
                  <DynamicField
                    {...field}
                    openChangeFieldModal={openChangeFieldModal}
                    deleteField={handleDeleteField}
                    setCustomFields={setCustomFields}
                    key={index}
                    errors={errors}
                    customFields={customFields}
                  />
                );
              })}

              {/* Add field */}
              <Button
                size="small"
                variant="primary"
                startIcon={<PlusIcon />}
                className="program-details__form__add-field"
                onClick={handleToggleOpenAddFieldsModal}
              >
                Add Field
              </Button>
            </Form>
          );
        }}
      </Formik>
      {isAddProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isAddProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenAddFieldsModal}
          title="Add Fields"
          onSelection={handleAddField}
        />
      )}
      {isEditProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isEditProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenEditFieldsModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          onSelection={changeFieldType}
        />
      )}
    </div>
  );
};

export default ProgramDetails;
