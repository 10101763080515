import React, { useState } from 'react'
import CohortRelationship from '../CohortRelationship/CohortRelationship'
import MileStone from '../Milestone/MileStone'
import Resources from '../Resources/Resources'

export default function ViewCohort() {
    const [activeScreen, setActiveScreen] = useState('relationship')
    const handleAction = (screen: string) => {
        setActiveScreen(screen)
    }
    return (
        <>
            {activeScreen === 'relationship'
                ? (
                    <CohortRelationship handleAction={handleAction} />
                )
                : activeScreen === 'Milestone'
                    ? (
                        <MileStone handleAction={handleAction} />
                    )
                    : activeScreen === 'Resource'
                        ? (
                            <Resources handleAction={handleAction}/>
                        )
                        : (<></>)
            }
        </>
    )
}
