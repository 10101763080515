import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from './dropdown.module.scss';
import { TDropdown, TDropdownOptions, ThreeDotsDropdown, Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import { TickMarkIcon } from 'src/components/common/common.icons';
import { ViewApplicationSelf } from 'src/pages/viewMyApplication/ViewMyApplication';
import {
  TCreateProgramGroupActionRequest,
  TGetProgramApplication
} from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity, useToggleOpenModal } from 'src/hooks';
import { formatDate } from 'src/utils';
import { useToggleCreateEditGroupModal } from 'src/components/chat';
import { TUserTypes } from 'src/constants/user.constants';
import { createProgramGroup } from 'src/store/chat/chat.slice';

import './applicationActions.scss';

import AdvanceApplication from '../advanceApplication/AdvanceApplication';
import ShareDemoInvite from '../shareDemoInvite/ShareDemoInvite';
import CreateEditGroup from '../../program/discussion/discussionList/createEditGroup/CreateEditGroup';
import useGetProgramMembers from '../../program/programMembers/useGetProgramMembers.hook';
import { Dropdown } from 'react-bootstrap';
import { InputField } from 'src/components/common/formFields';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';

const shareProgramDropdownId = 'shareProgramDropdown';

const ApplicationActions = () => {
  // Hooks
  const isUserViewingApplication = useContext(ViewApplicationSelf);
  const {
    application: viewApplication,
    companyId,
    isFinalStage,
    stage
  }: TGetProgramApplication = useAppSelector((store) => store.program.viewApplication);
  const modalId: string = useAppSelector((store) => store.common.modalId);
  const navigate = useNavigate();
  const toggleOpenDropdown = useToggleOpenModal();
  const { getIdentities } = useGetIdentity();
  const { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen } =
    useToggleCreateEditGroupModal({ mode: 'Add' });
  const dispatch = useAppDispatch();

  const { loggedInCompanyId, authenticatedId, loggedInUserType } = getIdentities();

  // Constants
  const { id: applicationId, programId, User } = viewApplication;
  const advanceModalId = `advanceModalId-${applicationId}`;
  const shareDemoInviteId = `shareDemoInviteId-${applicationId}`;
  const isAdvanceApplicationModalOpen: boolean = modalId === advanceModalId;
  const isShareDemoInviteModalOpen: boolean = modalId === shareDemoInviteId;
  const isViewerProgramCreator = loggedInCompanyId === companyId;
  const applicantId = viewApplication?.User?.id;
  const [nonPlatformEmail, setNonPlatformEmail] = useState<string>('')
  const [keyMemberData, setKeyMemberData] = useState<any>([])

  const fetchProgramMembers = useGetProgramMembers({ programId: `${programId}` });

  const handleOpenAdvanceApplicationModal = () => {
    toggleOpenDropdown({ modalId: advanceModalId });
  };

  const handleOpenShareDemoModal = () => {
    toggleOpenDropdown({ modalId: shareDemoInviteId });
  };

  const handleViewCompany = () => {
    navigate(`/view-company?id=${companyId}`);
  };

  const shareProgramDropdownOptions: TDropdownOptions[] = [
    {
      label: 'View applicant’s profile',
      onClick: () => {
        navigate(`/view-profile?id=${applicantId}`);
      }
    },

    {
      label: 'Create discussion group',
      onClick: toggleCreateEditGroupModal
    }
  ];

  const showActionButtons = useMemo(() => {
    if (!isViewerProgramCreator) {
      return false;
    }

    return true;
  }, [isUserViewingApplication, isViewerProgramCreator]);

  const showViewApplicantProfileButton = useMemo(() => {
    if (isViewerProgramCreator) {
      return false;
    }
    if (isUserViewingApplication && !showActionButtons) {
      return false;
    }

    return true;
  }, [isUserViewingApplication, isViewerProgramCreator, showActionButtons]);

  const shareProgramDropdownProps: TDropdown = {
    id: shareProgramDropdownId,
    size: 'large',
    alignment: 'left',
    items: shareProgramDropdownOptions
  };

  const handleCreateGroup = (formValues: { groupName: string; usersToAdd: string[] }) => {
    const usersToAdd: string[] = formValues?.usersToAdd;

    const usersToAddObj: {
      userId: string;
      userType: TUserTypes;
    }[] = usersToAdd.map((userId) => ({ userId, userType: 'User' }));
    // Adding the owner as the first item in the array.
    usersToAddObj.unshift({ userId: loggedInCompanyId, userType: 'Company' });

    // Only program members (userType = 'User') can be added to the group
    const payload: TCreateProgramGroupActionRequest = {
      programId: `${programId}`,
      groupName: formValues?.groupName,
      usersToAdd: usersToAddObj,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(createProgramGroup(payload));
  };

  useEffect(() => {
    if (companyId) {
      fetchProgramMembers();
    }
  }, [companyId])
  useEffect(() => {
    if (programId) {
      getMemberData()
    }
  }, [programId]);
  async function getMemberData() {
    const response: any = await httpRequest(`company/get-members?companyId=${companyId}`, 'GET', null, 'json', 'company')
    if (response?.data?.users?.length > 0) {
      setKeyMemberData(response?.data?.users)
    }
  }
  function validateEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  async function handleInvite(email: string, type: string) {
    if (email) {
      if (!validateEmail(email)) {
        dispatch(
          openPopup({
            popupMessage: 'Email is not valid',
            popupType: 'error'
          })
        )
        return
      }
      const evaluationArray = [
        {
          applicationTitle: `${User?.name}`,
          stageId: `${stage?.id}`,
          applicationId: `${applicationId}`,
          userId: `${User?.id}`
        }
      ]
      const body = {
        mentorEmail: email,
        programId: `${programId}`,
        userType: loggedInUserType,
        evaluationArray
      }
      const repsonse = await httpRequest('application/sendForEvaluation', 'POST', body, 'json', 'program');
      if (repsonse.status) {
        setNonPlatformEmail('')
        dispatch(
          openPopup({
            popupMessage: 'invitation sent successfully',
            popupType: 'success'
          })
        );
      } else {
        dispatch(
          openPopup({
            popupMessage: repsonse?.message,
            popupType: 'error'
          })
        );
      }
    }
  }
  return (
    <div className="application-actions">
      <div className="application-actions__label">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          {/* {isUserViewingApplication ? 'Applied Program' : 'Application Details'} */}
          {isUserViewingApplication ? 'Applied Program' : ''}
        </Typography>
        <div className="application-actions__label__stage">
          {isUserViewingApplication && <TickMarkIcon color={colors.success[800]} />}
          <Typography as="span" variant="caption" fontWeight="medium">
            {/* {isUserViewingApplication ? 'Applied' : stage?.stageTitle} */}
            {isUserViewingApplication ? 'Applied' : ''}
          </Typography>
        </div>
        {isUserViewingApplication && (
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            style={{ color: colors.neutral[800] }}
          >
            {formatDate({ dateString: viewApplication?.updatedAt })}
          </Typography>
        )}
      </div>
      <div className="application-actions__actions">
        {showActionButtons && (
          <>
            {/*
             ** Advance to next stage
             */}
            {stage?.stagesDetails && stage?.stagesDetails?.length > 0 && !isUserViewingApplication && <div className={cx.dotDropdown}>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-6">
                  Invite for rate
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
                    <div className={cx.body} onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
                      <div className={cx.nonPlatformUser}>
                        <InputField
                          className={cx.critriaInput}
                          label={'Mentor Email'}
                          id="criteriaName"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNonPlatformEmail(e.target.value)}
                          value={nonPlatformEmail}
                          variant={componentSizeVariantsEnum.SMALL}
                          placeholder="Mentor Email"
                          type="text"
                          errorMessage={''}
                        />
                        <button className={cx.sentButton} onClick={() => handleInvite(nonPlatformEmail, 'non-platform')}>
                          Send Invite
                        </button>
                      </div>
                      {keyMemberData?.length > 0 && <p style={{ textAlign: 'center', margin: '0' }}>or</p>}
                      <ul className={cx.mentorList}>
                        {keyMemberData?.map((member: any) => {
                          return (
                            <li key={member?.id} onClick={() => handleInvite(member.email, 'list')}>{member?.name}</li>
                          )
                        })}
                      </ul>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>}
            {!isFinalStage && (
              <Button
                size="extraSmall"
                variant="primary"
                className="application-actions__actions__next"
                type="button"
                onClick={handleOpenAdvanceApplicationModal}
              >
                Advance to next stage
              </Button>
            )}
            {/*
             ** Share demo invite
             */}
            <Button
              size="extraSmall"
              variant="secondary"
              className="application-actions__actions__share"
              type="button"
              onClick={handleOpenShareDemoModal}
            >
              Share demo invite
            </Button>
            <div className="application-actions__actions__dropdown">
              <ThreeDotsDropdown dropdownOptions={shareProgramDropdownProps} />
            </div>
          </>
        )}
        {isUserViewingApplication && (
          <Button
            size="small"
            variant="secondary"
            className="application-actions__actions__view-company"
            type="button"
            onClick={handleViewCompany}
          >
            View Company Profile
          </Button>
        )}
        {showViewApplicantProfileButton && (
          <Button
            size="extraSmall"
            variant="secondary"
            className="application-actions__actions__view-applicant"
            type="button"
            onClick={() => {
              navigate(`/view-profile?id=${viewApplication?.User?.id}`);
            }}
          >
            View applicants profile
          </Button>
        )}
      </div>
      {isAdvanceApplicationModalOpen && (
        <AdvanceApplication
          isModalOpen={isAdvanceApplicationModalOpen}
          onModalClose={handleOpenAdvanceApplicationModal}
          programId={programId}
          applicationId={applicationId}
        />
      )}
      {isShareDemoInviteModalOpen && (
        <ShareDemoInvite
          isModalOpen={isShareDemoInviteModalOpen}
          onModalClose={handleOpenShareDemoModal}
          programId={programId}
          userId={viewApplication?.User?.id}
          applicationId={applicationId}
        />
      )}{' '}
      {isToggleCreateEditGroupModalOpen && (
        <CreateEditGroup
          mode="Add"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          onSubmit={handleCreateGroup}
        />
      )}
    </div>
  );
};

export default ApplicationActions;
