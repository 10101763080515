import { MouseEvent, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import {
  Card,
  ConfirmModal,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography,
  VerticalSeparator
} from 'src/components/common';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';
import {
  useCopyToClipboard,
  useGetIdentity,
  useToggleOpenDropdown,
  useToggleOpenModal
} from 'src/hooks';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TArchiveProgramRequest, TCloseProgramRequest } from 'src/services/apiEndpoint.types.ts';
import { archiveProgram, closeProgram, programList } from 'src/store/program/program.slice';
import { formatDate, textEllipsis, StorageUtils } from 'src/utils';
import CreateEditPostModal from 'src/components/posts/createEditPostModal/CreateEditPostModal';

import './cohortCard.scss';

import { TCohortCardProps } from './cohortCard.types';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
const CohortCard = ({ cohortTitle, startDate, endDate, companyId, id }: TCohortCardProps) => {
  const navigate = useNavigate();
  function handleNavigate() {
    navigate(`/view-cohort?id=${id}&companyId=${companyId}&tab=Dashboard`)
  }
  return (
    <>
      <Card className="program-card">
        <div className="program-card__content">
          <div className="program-card__content__details">
            <div className="program-card__content__details__heading">
              <Typography
                as="span"
                variant="body-1"
                fontWeight="semiBold"
                className="program-card__content__details__heading__cohortTitle"
              >
                {cohortTitle}
              </Typography>{' '}
              <VerticalSeparator />
            </div>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="program-card__content__details__start"
            >
              Start date: {formatDate({ dateString: startDate })}
            </Typography>{' '}
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="program-card__content__details__start"
            >
              End date: {formatDate({ dateString: endDate })}
            </Typography>
          </div>
        </div>

        <div className="program-card__footer">
          <Button
            className="program-card__footer__action"
            size="extraSmall"
            variant="secondary"
            onClick={handleNavigate}
          >
            View Details
          </Button>
        </div>
      </Card>
    </>
  );
};

export default CohortCard;
