import { useEffect, useState } from 'react';
import {
  InfiniteScroll,
  Loader,
  Modal,
  Separator,
  SubMenuModal,
  Typography
} from 'src/components/common';
import { SearchIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { updateProgram } from 'src/store/program/program.slice';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import {
  TEditProgramMembersRequest,
  TGetProgramMembersRequest,
  TProgramKeyMember
} from 'src/services/apiEndpoint.types.ts';
import { StorageUtils } from 'src/utils';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';

import './extendDeadlineProgram.scss';
// import { TExtendDealine } from './ExtendDeadlineProgram.types';
// import { httpRequest } from 'src/services/commonApis';
// import { extend } from 'lodash';

const searchUsersResultModalId = 'searchUsersResultModalId';
export type TExtendDealine= {
  isModalOpen: boolean;
  onModalClose: () => void;
  programId: string
};
const ExtendDeadlineProgram = ({
  isModalOpen,
  onModalClose,
  programId
}: TExtendDealine) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedProgramMembers, setSelectedProgramMembers] = useState<TProgramKeyMember[]>([]);
const payload = {
  programId,
  endDate: searchInput

}
const handleExtension = () => {
  dispatch(updateProgram(payload))
}
  const companyId = StorageUtils.get('companyId') as string;
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { dropdownId, loading }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const {
    programKeyMembers
  } = useAppSelector((store) => store.program);
  const { getIdentities } = useGetIdentity();

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  // const programId = paramValue({ paramName: 'id' }) as unknown as string;
  const [suggestedKeyMember, setSuggestedKeyMember] = useState<any>([])
  const openSearchUsersResultModalId =
    dropdownId === searchUsersResultModalId && (programKeyMembers?.length > 0 || loading);

  const filterSuggestedMember = suggestedKeyMember?.filter((item: TProgramKeyMember) => !selectedProgramMembers?.some((member: TProgramKeyMember) => member?.id === item?.id))
  const filterSearchMember = programKeyMembers?.filter((item: TProgramKeyMember) => !selectedProgramMembers?.some((member: TProgramKeyMember) => member?.id === item?.id))
  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      className="Extend-Dealine-modal"
      title="Extend Program Deadline"
    >
      <div className="Extend-Dealine">
        <div className="Extend-Dealine__search-users">
          <InputField
            label=" Update End Date For Program"
            id="email"
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Search user by name, email address"
            startIcon={<SearchIcon />}
            type="date"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(assignSubMenuModalId(searchUsersResultModalId));
            }}
          />
        </div>
      
        <Separator />
    
        <div className="Extend-Dealine__buttons">
          <Button variant="secondary" size="small" onClick={onModalClose}>
            Close
          </Button>
          <Button variant="primary" size="small" loading={loading} onClick={handleExtension}>
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExtendDeadlineProgram;
