import React from 'react'
import { PlusIconCircle } from 'src/components/common/common.icons';
import cx from './resources.module.scss';
import { Col, Row } from 'react-bootstrap';
import UploadedResourceBox from './UploadedResourceBox/UploadedResourceBox';
import { Button } from 'src/components/common';

export default function Resources({ handleAction }: any) {
  const handleResourceUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

  }
  return (
    <div className={`${cx.resources}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Cohort {'>'} Resources</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={() => handleAction('relationship')}
        >
          Back
        </Button>
      </div>
      <div className={cx.resourceContainer}>
        <div className='insideCard'>
          <Row>
            <Col md={4}>
              <div className={cx.imageUploadBox}>
                <div className={cx.uploadContent}>
                  {<label htmlFor='upload' className={cx.uploadLabel}>
                    <input className={cx.uploadInput} multiple onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleResourceUpload(e)} type='file' id='upload' accept='' />
                    <PlusIconCircle />
                    <h5 className={cx.uploadTitle}>Upload Resources</h5>
                  </label>}
                </div>
              </div>
            </Col>
            {[{ title: 'Test Title 1' }, { title: 'Test Title 2' }, { title: 'Test Title 3' }, { title: 'Test Title 4' }, { title: 'Test Title 5' }]?.map((resourceData: any) => {
              return (
                <Col md={4} key={resourceData?.id}>
                  <UploadedResourceBox resourceData={resourceData} />
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </div >
  )
}
