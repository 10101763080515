import { useMemo } from 'react';
import {
  Button,
  Typography
} from 'src/components/common';

import cx from './viewAction.module.scss';
import { TActionButton } from './viewCohort.types';
import { MdAdd } from 'react-icons/md';
import { useAppSelector } from 'src/store/hooks';
import { formatDate } from 'src/utils';
import { calculateRemainingTime } from 'src/utils/formatDate/formatDate';

const CohortAction = () => {
  const { viewCohort }: any = useAppSelector((store: any) => store.cohort);
  if (!viewCohort) {
    return <></>
  }
  const daysRemainingMiliSeconds = +new Date(viewCohort?.endDate) - +new Date(viewCohort?.startDate);
  const daysRemainingDate = new Date(daysRemainingMiliSeconds);

  return (
    <div className={cx.cohortActions}>
      <div className={cx.label}>
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Cohort Details
        </Typography>
      </div>
      <div className={cx.actions}>
        <div className={cx.header}>
          <h5 className={cx.cohortName}>{viewCohort?.cohortTitle}</h5>
          <div className={cx.dates}>
            <p>Start date: {formatDate({ dateString: viewCohort?.startDate })}</p>
            <p>End Date : {formatDate({ dateString: viewCohort?.endDate })}</p>
            <p>({calculateRemainingTime(viewCohort?.startDate, viewCohort?.endDate)} remaining)</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CohortAction;
