const formatDate = ({ dateString }: { dateString: string }): string => {
  try {
    const date = new Date(dateString);

    if (Number.isNaN(date?.getTime())) {
      return '-';
    }

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const getOrdinalSuffix = (n: number) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const remainder = n % 100;
      return n + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
    };

    const formattedDay = getOrdinalSuffix(day);

    return `${formattedDay} ${month} ${year}`;
  } catch {
    return '-';
  }
};

export function calculateRemainingTime(startDate: string, endDate: string) {
  if (startDate && endDate) {
    // Convert dates to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    let diffInMilliseconds = +end - +start;

    // Calculate the number of years
    const years = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 365));
    diffInMilliseconds -= years * 1000 * 60 * 60 * 24 * 365;

    // Calculate the number of months
    const months = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 30));
    diffInMilliseconds -= months * 1000 * 60 * 60 * 24 * 30;

    // Calculate the number of days
    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    // Format the result
    let result = '';
    if (years > 0) {
      result += `${years} year${years > 1 ? 's' : ''} `;
    }
    if (months > 0) {
      result += `${months} month${months > 1 ? 's' : ''} `;
    }
    if (days > 0 || (!years && !months)) {
      result += `${days} day${days > 1 ? 's' : ''}`;
    }

    return result.trim();
  } else {
    return '0 day'
  }
}

export default formatDate;
