import React, { useEffect } from 'react';
import cx from './teams.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ProfilePicture, Typography } from 'src/components/common';
import { useUrlParamValue } from 'src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { cohortTeamList } from 'src/store/cohort/cohort.slice';
import { AppDispatch } from 'src/store/store';

export default function Teams({ handleClick }: any) {
  const { paramValue } = useUrlParamValue();
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const { cohortTeam } = useSelector((state: any) => state.cohort)
  const { loading } = useSelector((state: any) => state.common)
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    if (cohortId) {
      dispatch(cohortTeamList({ cohortId }))
    }
  }, [cohortId])

  return (
    <div className={cx.teams}>
      <Row>
        <Col md={6}>
          <h5 className={`${cx.chatHeading}`}>Teams / Startups</h5>
          <div className='insideCard transparent'>
            <div className={cx.chatBox}>
              <Row>
                {cohortTeam?.map((team: any) => {
                  return (
                    <Col md={6} key={team.id}>
                      <div className={`${cx.teamCard}`} onClick={() => handleClick('Relationship')}>
                        <ProfilePicture
                          key={team.id}
                          url={''}
                          fallback={team.cohortTeamsTitle?.[0]?.toLocaleUpperCase()}
                        />
                        <div>
                          <Typography
                            as="p"
                            variant="body-2"
                            fontWeight="semiBold"

                          >
                            {team.cohortTeamsTitle}
                          </Typography>
                          <Typography
                            as="p"
                            variant="caption"
                            fontWeight="regular"

                          >
                            {team.onboardingTo}
                          </Typography>
                        </div>

                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={7}>
              <h5 className={`${cx.chatHeading}`}>Group Chat</h5>
              <div className='insideCard transparent'>
                <div className={cx.chatBox}>

                </div>
              </div>
            </Col>
            <Col md={5}>
              <h5 className={`${cx.chatHeading}`}>Upcoming Events</h5>
              <div className='insideCard'>
                <ul className={`${cx.activityList}`}>
                  <li>Test Events</li>
                  <li>Test Events</li>
                  <li>Test Events</li>
                </ul>
              </div>
              <h5 className={`${cx.chatHeading}`}>Deadline Approching</h5>
              <div className='insideCard'>
                <ul className={`${cx.activityList}`}>
                  <li>Test</li>
                  <li>Test</li>
                  <li>Test</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
