import React, { useEffect, useState } from 'react';
import cx from './milestone.module.scss';
import { Button, Loader } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useUrlParamValue } from 'src/hooks';
import { Form, Formik, FieldArray, Field } from 'formik';
import milesoneCohortValidationSchema from './milesoneCohortValidationSchema';
import { initialValues } from './initialValue';
import { useDispatch } from 'react-redux';
import { createCohortMilestone } from 'src/store/cohort/cohort.slice';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';

interface TOnMilestoneProps {
    handleBack: () => void;
}
export default function MileStone({ handleBack }: TOnMilestoneProps) {
    const { paramValue } = useUrlParamValue();
    const dispatch = useDispatch()
    const companyId: string = paramValue({ paramName: 'companyId' }) as string;
    const cohortId: string = paramValue({ paramName: 'id' }) as string;
    const [loading, setLoading] = useState<boolean>(false);
    const [initialMilestone, setInitialMilestone] = useState<any>([])
    const handleSubmit = async (values: any) => {
        const path = 'cohort/milestone/create';
        const data = { ...values.milestones?.[0], cohortId, userId: companyId }
        const newData = {
            cohortId: '',
            userId: '',
            cohortMilestoneTitle: '',
            startDate: '',
            endDate: '',
            tasks: [{ taskTitle: '', taskStartDate: '', taskEndDate: '' }]
        }

        const response = await httpRequest(path, 'POST', data, 'json', 'program');
        if (response?.status) {
            const path = `cohort/milestone/get?cohortId=${cohortId}&id=${response?.data?.id}&userId=${companyId}`
            const milestoneRes = await httpRequest(path, 'GET', null, 'json', 'program');
            setInitialMilestone({ milestones: [...milestoneRes?.data, newData] })
            // dispatch(
            //     openPopup({
            //         message: response?.message,
            //         popupType: 'success'
            //     })
            // );
            // handleBack()
        } else {
            dispatch(
                openPopup({
                    message: response?.message,
                    popupType: 'error'
                })
            );
        }
        setLoading(false)
    }
    console.log(initialMilestone, 'initialMilestone');

    useEffect(() => {
        setInitialMilestone(initialValues)
    }, [])

    return (
        <div className={`${cx.onboard}`}>
            <div className={`${cx.titleHeader}`}>
                <h5 className={`${cx.title}`}>Milestone</h5>
                <Button
                    size="small"
                    variant="primary"
                    className="program-actions__actions__next"
                    style={{ width: 'auto' }}
                    type="button"
                    onClick={handleBack}
                >
                    Back
                </Button>
            </div>

            <Formik
                initialValues={initialMilestone}
                onSubmit={handleSubmit}
                validationSchema={milesoneCohortValidationSchema}
                enableReinitialize
            >
                {({ values, errors, touched }) => {
                    return (
                        <Form noValidate>
                            <FieldArray name="milestones">
                                {({ insert, remove, push }) => (
                                    <>
                                        {values?.milestones?.map((milestone: any, i: number) => (
                                            <div className='insideCard' key={i}>
                                                <Row >
                                                    <Col md={3}>
                                                        <div className={`${cx.inputFields}`}>
                                                            <Field
                                                                name={`milestones.${i}.cohortMilestoneTitle`}
                                                            >
                                                                {({ field }) => (
                                                                    <InputField
                                                                        {...field}
                                                                        required
                                                                        label="Milestone"
                                                                        id={`milestone-${i}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Milestone"
                                                                        type="text"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={`${cx.inputFields}`}>
                                                            <Field
                                                                name={`milestones.${i}.startDate`}
                                                            >
                                                                {({ field }) => (
                                                                    <InputField
                                                                        {...field}
                                                                        required
                                                                        label="Start Date"
                                                                        id={`startDate-${i}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        type="date"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={`${cx.inputFields}`}>
                                                            <Field
                                                                name={`milestones.${i}.endDate`}
                                                            >
                                                                {({ field }) => (
                                                                    <InputField
                                                                        {...field}
                                                                        required
                                                                        label="End Date"
                                                                        id={`endDate-${i}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        type="date"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </Col>

                                                    <Col md={3}></Col>

                                                    {/* Nested FieldArray for tasks inside each milestone */}
                                                    <FieldArray name={`milestones.${i}.tasks`}>
                                                        {({ insert, remove, push }) => (
                                                            <>
                                                                {milestone.tasks?.map((task: any, t: number) => (
                                                                    <div className={cx.task} key={t}>
                                                                        <Row>
                                                                            <Col md={3}>
                                                                                <div className={`${cx.inputFields}`}>
                                                                                    <Field
                                                                                        name={`milestones.${i}.tasks.${t}.taskTitle`}
                                                                                    >
                                                                                        {({ field }) => (
                                                                                            <InputField
                                                                                                {...field}
                                                                                                required
                                                                                                label="Task"
                                                                                                id={`task-${i}-${t}`}
                                                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                                                placeholder="Task"
                                                                                                type="text"
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className={`${cx.inputFields}`}>
                                                                                    <Field
                                                                                        name={`milestones.${i}.tasks.${t}.startDate`}
                                                                                    >
                                                                                        {({ field }) => (
                                                                                            <InputField
                                                                                                {...field}
                                                                                                required
                                                                                                label="Start Date"
                                                                                                id={`startDate-${i}-${t}`}
                                                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                                                type="date"
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className={`${cx.inputFields}`}>
                                                                                    <Field
                                                                                        name={`milestones.${i}.tasks.${t}.endDate`}
                                                                                    >
                                                                                        {({ field }) => (
                                                                                            <InputField
                                                                                                {...field}
                                                                                                required
                                                                                                label="End Date"
                                                                                                id={`endDate-${i}-${t}`}
                                                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                                                type="date"
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                {(t === milestone.tasks?.length - 1)
                                                                                    ? <div className={cx.addTask} onClick={() => push({ taskTitle: '', taskStartDate: '', taskEndDate: '' })}>
                                                                                        <span className={cx.addRemoveIcon}>
                                                                                            {loading ? <Loader /> : <MdAdd />}
                                                                                        </span>
                                                                                    </div>
                                                                                    : (
                                                                                        <div className={cx.addTask} onClick={() => remove(t)}>
                                                                                            <span className={cx.addRemoveIcon}>
                                                                                                <MdRemove />
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                    <div className={`${cx.actionButton}`}>
                                                        {(i === values?.milestones?.length - 1) && (
                                                            <button className={`${cx.addMilestone}`} type='submit'
                                                            // onClick={() =>
                                                            //     push({
                                                            //         cohortId: '',
                                                            //         userId: '',
                                                            //         cohortMilestoneTitle: '',
                                                            //         startDate: '',
                                                            //         endDate: '',
                                                            //         tasks: [{ taskTitle: '', taskStartDate: '', taskEndDate: '' }]
                                                            //     })
                                                            // }
                                                            >
                                                                <MdAdd className={`${cx.addRemoveBtn}`} />
                                                            </button>
                                                        )}
                                                        {i !== 0 && <div className={`${cx.addMilestone}`} onClick={() => remove(i)}>
                                                            <MdRemove className={`${cx.addRemoveBtn}`} />
                                                        </div>}
                                                    </div>
                                                </Row>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </FieldArray>

                            <Row>
                                <Col md={3}>
                                    <Button variant="primary" type="submit" size="small">
                                        {loading ? <Loader /> : 'Submit'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
// import React, { useState } from 'react';
// import cx from './milestone.module.scss';
// import { Button, Loader } from 'src/components/common';
// import { InputField } from 'src/components/common/formFields';
// import { componentSizeVariantsEnum } from 'src/constants';
// import { Col, Row } from 'react-bootstrap';
// import { MdAdd, MdRemove } from 'react-icons/md';
// import { useUrlParamValue } from 'src/hooks';
// import { Form, Formik, FieldArray, Field } from 'formik';
// import milesoneCohortValidationSchema from './milesoneCohortValidationSchema';
// import { initialValues } from './initialValue';
// import { useDispatch } from 'react-redux';
// import { httpRequest } from 'src/services/commonApis';
// import { openPopup } from 'src/store/common/common.slice';
// import { getCohortTeamSuccess, getMilestone } from 'src/store/cohort/cohort.slice';

// interface TOnMilestoneProps {
//     handleBack: () => void;
// }

// export default function MileStone({ handleBack }: TOnMilestoneProps) {
//     const { paramValue } = useUrlParamValue();
//     const dispatch = useDispatch();
//     const companyId: string = paramValue({ paramName: 'companyId' }) as string;
//     const cohortId: string = paramValue({ paramName: 'id' }) as string;
//     const [loading, setLoading] = useState<boolean>(false);

//     const handleSubmit = async (values: any) => {
//         const path = 'cohort/milestone/create';
//         const data = { ...values, cohortId, userId: companyId };
//         setLoading(true);
//         const response = await httpRequest(path, 'POST', data, 'json', 'program');
//         if (response?.status) {
//             const path = `cohort/milestone/get?cohortId=${cohortId}&id=${response?.data?.id}&userId=${companyId}`
//             const milestoneRes = await httpRequest(path, 'GET', null, 'json', 'program')
//             dispatch(getCohortTeamSuccess(milestoneRes?.data[0] || null))
//             // dispatch(
//             //     openPopup({
//             //         message: response?.message,
//             //         popupType: 'success'
//             //     })
//             // );

//             // handleBack();
//         } else {
//             dispatch(
//                 openPopup({
//                     message: response?.message,
//                     popupType: 'error'
//                 })
//             );
//         }
//         setLoading(false);
//     };

//     return (
//         <div className={`${cx.onboard}`}>
//             <div className={`${cx.titleHeader}`}>
//                 <h5 className={`${cx.title}`}>Milestone</h5>
//                 <Button
//                     size="small"
//                     variant="primary"
//                     className="program-actions__actions__next"
//                     style={{ width: 'auto' }}
//                     type="button"
//                     onClick={handleBack}
//                 >
//                     Back
//                 </Button>
//             </div>

//             <Formik
//                 initialValues={initialValues}
//                 onSubmit={handleSubmit}
//                 validationSchema={milesoneCohortValidationSchema}
//                 enableReinitialize
//             >
//                 {({ values }) => (
//                     <div className='insideCard'>
//                         <Form noValidate>
//                             <Row>
//                                 <Col md={3}>
//                                     <div className={`${cx.inputFields}`}>
//                                         <Field
//                                             name="cohortMilestoneTitle"
//                                         >
//                                             {({ field }) => (
//                                                 <InputField
//                                                     {...field}
//                                                     required
//                                                     label="Milestone"
//                                                     id="cohortMilestoneTitle"
//                                                     variant={componentSizeVariantsEnum.SMALL}
//                                                     placeholder="Milestone"
//                                                     type="text"
//                                                 />
//                                             )}
//                                         </Field>
//                                     </div>
//                                 </Col>
//                                 <Col md={3}>
//                                     <div className={`${cx.inputFields}`}>
//                                         <Field
//                                             name="startDate"
//                                         >
//                                             {({ field }) => (
//                                                 <InputField
//                                                     {...field}
//                                                     required
//                                                     label="Start Date"
//                                                     id="startDate"
//                                                     variant={componentSizeVariantsEnum.SMALL}
//                                                     type="date"
//                                                 />
//                                             )}
//                                         </Field>
//                                     </div>
//                                 </Col>
//                                 <Col md={3}>
//                                     <div className={`${cx.inputFields}`}>
//                                         <Field
//                                             name="endDate"
//                                         >
//                                             {({ field }) => (
//                                                 <InputField
//                                                     {...field}
//                                                     required
//                                                     label="End Date"
//                                                     id="endDate"
//                                                     variant={componentSizeVariantsEnum.SMALL}
//                                                     type="date"
//                                                 />
//                                             )}
//                                         </Field>
//                                     </div>
//                                 </Col>
//                             </Row>

//                             {/* Nested FieldArray for tasks inside the milestone */}
//                             <FieldArray name="tasks">
//                                 {({ insert, remove, push }) => (
//                                     <>
//                                         {values.tasks.map((task, t) => (
//                                             <div className={cx.task} key={t}>
//                                                 <Row>
//                                                     <Col md={3}>
//                                                         <div className={`${cx.inputFields}`}>
//                                                             <Field
//                                                                 name={`tasks.${t}.taskTitle`}
//                                                             >
//                                                                 {({ field }) => (
//                                                                     <InputField
//                                                                         {...field}
//                                                                         required
//                                                                         label="Task"
//                                                                         id={`task-${t}`}
//                                                                         variant={componentSizeVariantsEnum.SMALL}
//                                                                         placeholder="Task"
//                                                                         type="text"
//                                                                     />
//                                                                 )}
//                                                             </Field>
//                                                         </div>
//                                                     </Col>
//                                                     <Col md={3}>
//                                                         <div className={`${cx.inputFields}`}>
//                                                             <Field
//                                                                 name={`tasks.${t}.startDate`}
//                                                             >
//                                                                 {({ field }) => (
//                                                                     <InputField
//                                                                         {...field}
//                                                                         required
//                                                                         label="Task Start Date"
//                                                                         id={`taskStartDate-${t}`}
//                                                                         variant={componentSizeVariantsEnum.SMALL}
//                                                                         type="date"
//                                                                     />
//                                                                 )}
//                                                             </Field>
//                                                         </div>
//                                                     </Col>
//                                                     <Col md={3}>
//                                                         <div className={`${cx.inputFields}`}>
//                                                             <Field
//                                                                 name={`tasks.${t}.endDate`}
//                                                             >
//                                                                 {({ field }) => (
//                                                                     <InputField
//                                                                         {...field}
//                                                                         required
//                                                                         label="Task End Date"
//                                                                         id={`taskEndDate-${t}`}
//                                                                         variant={componentSizeVariantsEnum.SMALL}
//                                                                         type="date"
//                                                                     />
//                                                                 )}
//                                                             </Field>
//                                                         </div>
//                                                     </Col>
//                                                     <Col md={3}>
//                                                         {(t === values?.tasks?.length - 1)
//                                                             ? <div className={cx.addTask} onClick={() => push({ taskTitle: '', taskStartDate: '', taskEndDate: '' })}>
//                                                                 <span className={cx.addRemoveIcon}>
//                                                                     <MdAdd />
//                                                                 </span>
//                                                             </div>
//                                                             : (
//                                                                 <div className={cx.addTask} onClick={() => remove(t)}>
//                                                                     <span className={cx.addRemoveIcon}>
//                                                                         <MdRemove />
//                                                                     </span>
//                                                                 </div>
//                                                             )}
//                                                     </Col>
//                                                 </Row>
//                                             </div>
//                                         ))}

//                                     </>
//                                 )}
//                             </FieldArray>
//                             <div className={`${cx.actionButton}`}>
//                                 {(
//                                     <button className={`${cx.addMilestone}`} type='submit'>
//                                         <MdAdd className={`${cx.addRemoveBtn}`} />
//                                     </button>
//                                 )}
//                                 {/* {i !== 0 && <div className={`${cx.addMilestone}`} onClick={() => remove(i)}>
//                                 <MdRemove className={`${cx.addRemoveBtn}`} />
//                             </div>} */}
//                             </div>

//                             {/* <Row>
//                             <Col md={3}>
//                                 <Button variant="primary" type="submit" size="small">
//                                     {loading ? <Loader /> : 'Submit'}
//                                 </Button>
//                             </Col>
//                         </Row> */}
//                         </Form>
//                     </div>
//                 )}
//             </Formik>
//         </div>
//     );
// } 
