import React from 'react';
import cx from '../../viewCohort/viewCohort.module.scss';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import { Col, Row, Tooltip } from 'react-bootstrap';
import TrackStepper from 'src/components/common/TrackStepper/ActionStepper';
import db from './dashboard.module.scss'
import CohortAction from '../../viewCohort/CohortActions';
import { TActionButton } from '../../viewCohort/viewCohort.types';
import { MdAdd } from 'react-icons/md';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { colors } from '@mui/material';

export default function CohortDashboard({ handleClick, actionButtons }: { handleClick: (id: string) => void, actionButtons: any }) {
    const steps: any = [
        { title: 'Milestone1' },
        { title: 'Milestone2' },
        { title: 'Milestone2' },
        { title: 'Milestone4' }
    ];

    const data = [
        {
            date: '05-feb',
            Total_Allotment: 4000,
            Total_Expense: 2400
        },
        {
            date: '06-feb',
            Total_Allotment: 4000,
            Total_Expense: 5000
        },
        {
            date: '07-feb',
            Total_Allotment: 9000,
            Total_Expense: 2400
        },
        {
            date: '08-feb',
            Total_Allotment: 4000,
            Total_Expense: 3600
        },
        {
            date: '09-feb',
            Total_Allotment: 7000,
            Total_Expense: 5000
        },
        {
            date: '10-feb',
            Total_Allotment: 2000,
            Total_Expense: 3900
        },
        {
            date: '11-feb',
            Total_Allotment: 2000,
            Total_Expense: 2200
        },
        {
            date: '12-feb',
            Total_Allotment: 1000,
            Total_Expense: 3100
        }
    ];
    return (
        <div className={cx.dashboard}>
            <h5>Dashboard</h5>
            <div className={cx.actionButtons}>
                {actionButtons?.map((button: TActionButton) => {
                    return (
                        <Button
                            size="small"
                            variant="primary"
                            className="program-actions__actions__next"
                            type="button"
                            onClick={() => button.onClick(button.id)}
                            key={button.id}
                        >
                            <span className={cx.addIcon}><MdAdd /></span><span>{button.text} </span>
                        </Button>
                    )
                })}
            </div>
            <div className={cx.dashboardCard}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={4}>
                                <div className={cx.dashboardCardItem}>
                                    <div className={`${cx.dashboardItem}`} >
                                        <span className={`${cx.itemNumber}`}>20</span>
                                        <span className={`${cx.itemName}`}>Startups/Teams</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={cx.dashboardCardItem}>
                                    <div className={`${cx.dashboardItem}`} >
                                        <span className={`${cx.itemNumber}`}>20</span>
                                        <span className={`${cx.itemName}`}>Team Member</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={cx.dashboardCardItem}>
                                    <div className={`${cx.dashboardItem}`} >
                                        <span className={`${cx.itemNumber}`}>10</span>
                                        <span className={`${cx.itemName}`}>Mentors Assigned</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={cx.dashboardCardItem}>
                                    <div className={`${cx.dashboardItem}`} >
                                        <span className={`${cx.itemNumber}`}>10</span>
                                        <span className={`${cx.itemName}`}>Funds Alloted</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={cx.dashboardCardItem}>
                                    <div className={`${cx.dashboardItem}`} >
                                        <span className={`${cx.itemNumber}`}>10</span>
                                        <span className={`${cx.itemName}`}>Expense in total</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={cx.dashboardCardItem}>
                                    <div className={`${cx.dashboardItem}`} >
                                        <span className={`${cx.itemNumber}`}>10</span>
                                        <span className={`${cx.itemName}`}>Fund Balance</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <div className='insideCard'>
                            <ul className={cx.milestonelist}>
                                <li>
                                    <div className={'milestoneBox'}>
                                        <span className={cx.mileStoneHeading}>Milestone 1</span>
                                        <div className={`${cx.inputFields}`}>
                                            <InputField
                                                label=""
                                                id="completion"
                                                style={{ padding: '5px' }}
                                                className='milestonePercentageCompletion'
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="% completion"
                                                type="text"
                                            />
                                        </div>
                                        <div className={`${cx.inputFields}`}>
                                            <InputField
                                                label=""
                                                id="comments"
                                                style={{ padding: '5px', textAlign: 'center' }}
                                                className={`${cx.comments}`}
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Comments"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <ul className={cx.nestedList}>
                                        <li className={cx.task}>
                                            <div className={'milestoneBox'}>
                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="completion"
                                                        style={{ padding: '5px' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="% completion"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="comments"
                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="Comments"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li className={cx.task}>
                                            <div className={'milestoneBox'}>
                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="completion"
                                                        style={{ padding: '5px' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="% completion"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="comments"
                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="Comments"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li><hr /></li>
                                <li>
                                    <div className={'milestoneBox'}>
                                        <span className={cx.mileStoneHeading}>Milestone 2</span>
                                        <div className={`${cx.inputFields}`}>
                                            <InputField
                                                label=""
                                                id="completion"
                                                style={{ padding: '5px' }}
                                                className='milestonePercentageCompletion'
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="% completion"
                                                type="text"
                                            />
                                        </div>
                                        <div className={`${cx.inputFields}`}>
                                            <InputField
                                                label=""
                                                id="comments"
                                                style={{ padding: '5px', textAlign: 'center' }}
                                                className={`${cx.comments}`}
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Comments"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <ul className={cx.nestedList}>
                                        <li className={cx.task}>
                                            <div className={'milestoneBox'}>
                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="completion"
                                                        style={{ padding: '5px' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="% completion"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="comments"
                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="Comments"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li className={cx.task}>
                                            <div className={'milestoneBox'}>
                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="completion"
                                                        style={{ padding: '5px' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="% completion"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className={`${cx.inputFields}`}>
                                                    <InputField
                                                        label=""
                                                        id="comments"
                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                        className={`${cx.comments}`}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder="Comments"
                                                        type="text"
                                                    />
                                                </div>
                                                
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={cx.dashboardCard}>
                <Row>
                    <Col md={12} style={{ marginBottom: '15px' }}>
                        <div className={cx.stepper}>
                            <TrackStepper steps={steps} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <h5 className={`${cx.chatHeading}`}>Upcoming Events</h5>
                        <div className='insideCard'>
                            <ul className={`${cx.activityList}`}>
                                <li>Test Events</li>
                                <li>Test Events</li>
                                <li>Test Events</li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        <h5 className={`${cx.chatHeading}`}>Deadline Approching</h5>
                        <div className='insideCard'>
                            <ul className={`${cx.activityList}`}>
                                <li>Test</li>
                                <li>Test</li>
                                <li>Test</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={`${db.chartContainer}`}>
                            <BarChart
                                width={700}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                {/* <XAxis
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    height={1}
                                    scale="band"
                                    xAxisId="quarter"
                                /> */}
                                <YAxis />
                                <Tooltip />
                                <Legend content={<CustomLegend payload={[{ color: 'red' }]} />} />
                                <Bar dataKey="Total_Expense" fill="#fa4616" />
                                <Bar dataKey="Total_Allotment" fill="#ffe5d5" />
                            </BarChart>
                        </div>
                    </Col>
                </Row>
            </div >
        </div >
    )
}

const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
        <ul className={db.legendList}>
            {payload.map((entry: any, index: any) => (
                <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 10, height: 10, backgroundColor: entry.color, marginRight: 5 }} />
                    {/* {entry.value}: {entry.payload[entry.dataKey]} */}
                    {entry.value}: 2000
                </li>
            ))}
        </ul>
    );
};
