import React from 'react';
import cx from './cohortsidebar.module.scss';
import { useAppSelector } from 'src/store/hooks';

export default function CohortSidebar({ handleClick, activeTab }: any) {
    const { viewCohort }: any = useAppSelector((store: any) => store.cohort);
    if (!viewCohort) {
        return <></>
    }
    const isInvestmentTracking = viewCohort?.templates?.investmentTracking || false
    const iskpiTrackingTracking = viewCohort?.templates?.kpiTracking || false
    return (
        <div className={`${cx.sidebar}`}>
            <div className="insideCard">
                <ul className={cx.sidebaritemList}>
                    <li className={activeTab === 'Dashboard' ? cx.active : ''} onClick={() => handleClick('Dashboard')}>Dashboard</li>
                    <li className={(activeTab === 'Teams' || activeTab === 'Relationship') ? cx.active : ''} onClick={() => handleClick('Teams')}>Teams</li>
                    {isInvestmentTracking && <li className={activeTab === 'Investment' ? cx.active : ''} onClick={() => handleClick('Investment')}>Investment Tracking</li>}
                    {iskpiTrackingTracking && <li className={activeTab === 'Kpi' ? cx.active : ''} onClick={() => handleClick('Kpi')}>KPI Tracking</li>}
                    <li className={activeTab === 'Resource' ? cx.active : ''} onClick={() => handleClick('Resource')}>Resource</li>
                </ul>
            </div>
        </div>
    )
}
