import React, { useState } from 'react';
import cx from './mentor.module.scss';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';

interface TOnMilestoneProps {
  handleBack: () => void;
}
export default function Mentor({ handleBack }: TOnMilestoneProps) {
  return (
    <div className={`${cx.onboard}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Mentor</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <Row>
        <Col md={6}>
          <h5 className={`${cx.chatHeading}`}>Teams</h5>
          <div className='insideCard transparent'>
            <div className={cx.teamContainer}>
              <Row>
                <Col md={6}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'A'}
                    />
                    <div>
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"

                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"

                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>

                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'A'}
                      className="key-member-card__header__user-info__profile-pic"
                    />
                    <div className="key-member-card__header__user-info__user">
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"
                        className="key-member-card__header__user-info__user__name"
                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="key-member-card__header__user-info__user__role"
                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'A'}
                      className="key-member-card__header__user-info__profile-pic"
                    />
                    <div className="key-member-card__header__user-info__user">
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"
                        className="key-member-card__header__user-info__user__name"
                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="key-member-card__header__user-info__user__role"
                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'A'}
                      className="key-member-card__header__user-info__profile-pic"
                    />
                    <div className="key-member-card__header__user-info__user">
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"
                        className="key-member-card__header__user-info__user__name"
                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="key-member-card__header__user-info__user__role"
                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <h5 className={`${cx.chatHeading}`}>Mentor</h5>
          <div className='insideCard transparent'>
            <div className={cx.teamContainer}>
              <Row>
                <Col md={12}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label="Mentor's name"
                      id="mentorName"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Mentor's name"
                      type="search"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'T'}
                    />
                    <div>
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"

                      >
                        {'Taran adarsh'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                      >
                        Mentor
                      </Typography>
                    </div>

                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
