import React, { useState } from 'react';
import cx from './onBoard.module.scss';
import { Button, Loader, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import OptionsField from '../../applyToProgram/previewField/OptionsField';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Form, Formik } from 'formik';
import onBoardCohortValidationSchema from './onBoardCohortValidationSchema';
import { TCreateTeam } from './createTeamType';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import { cohortTeamSearch, resetTeamSearch, searchedTeamList } from 'src/store/cohort/cohort.slice';
import { TSearchUsersRequest } from 'src/services/apiEndpoint.types.ts';
import { useDispatch } from 'react-redux';
import { useUrlParamValue } from 'src/hooks';
import { TSearchCohort } from 'src/store/cohort/cohort.type';
import { httpRequest } from 'src/services/commonApis';
import { useAppSelector } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';

interface TOnboardProps {
  handleBack: () => void;
}
const initialValues: TCreateTeam = {
  cohortId: '',
  onboardingTo: '',
  cohortTeamsTitle: '',
  teamMemberIds: []
}
export default function OnBoard({ handleBack }: TOnboardProps) {
  const { paramValue } = useUrlParamValue();
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const { searchedTeam }: any = useAppSelector((store: any) => store.cohort);
  const dispatch = useDispatch()
  const [searchKey, setSearchKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (values: TCreateTeam) => {
    const payload = { ...values, cohortId }
    const response = await httpRequest('cohort/team/create', 'POST', payload, 'json', 'program');
    if (response?.status) {
      dispatch(
        openPopup({
          popupMessage: response.message,
          popupType: 'success'
        })
      );
      handleBack()
    } else {
      dispatch(
        openPopup({
          popupMessage: response.message,
          popupType: 'error'
        })
      );
    }
  }
  const fetchUsers = async ({ resetUsers = false }: { resetUsers: boolean }) => {
    dispatch(resetTeamSearch())
    setLoading(true)
    if (searchKey) {
      const data: TSearchCohort = {
        key: searchKey,
        page: 1,
        pageSize: 20,
        companyId
      };
      const path = `company/search-users?key=${searchKey}&page=${1}&pageSize=${10}&companyId=${companyId}`
      const response = await httpRequest(path, 'GET', null, 'json', 'company')
      if (response?.message === 'Users') {
        dispatch(searchedTeamList(response?.data?.users || []));
      } else {
        dispatch(searchedTeamList([]));
      }
    } else {
      dispatch(searchedTeamList([]));
    }
    setLoading(false)
  };
  console.log(searchedTeam, 'searchedTeam');

  useDebounce({
    func: () => fetchUsers({ resetUsers: true }),
    delay: 500,
    dependency: searchKey
  });
  return (
    <div className={`${cx.onboard}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>On-Board</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className='insideCard'>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={onBoardCohortValidationSchema}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form className="edit-cohort__form" noValidate>
                <Row>
                  <div className={cx.container}>
                    <Col md={3}>
                      <div className={`${cx.inputFields}`}>
                        <OptionsField options={['team', 'startup']} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('onboardingTo', e.target.value)} value={values?.onboardingTo} type='singleChoice' label="" id='onboardingTo' />
                      </div>
                    </Col>
                    <Col md={9}></Col>
                    <Col md={6}>
                      <div className={`${cx.inputFields}`}>
                        <InputField
                          required
                          label="Name"
                          id="cohortTeamsTitle"
                          value={values?.cohortTeamsTitle}
                          variant={componentSizeVariantsEnum.SMALL}
                          placeholder="Name"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={6}>
                      <div className={`${cx.inputFields}`}>
                        <InputField
                          label="Search and select your company ‘s team members"
                          id='team-search'
                          required={true}
                          variant={componentSizeVariantsEnum.SMALL}
                          placeholder="Search and add your company ‘s team members"
                          type="search"
                          value={searchKey}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6}></Col>
                  </div>
                  {!loading && searchedTeam?.map((team: any) => {
                    return (
                      <Col md={3} key={team?.id} id="teamMemberIds">
                        <div className={`${cx.teamCard} ${values.teamMemberIds.includes(team.id) ? cx.active : ''}`}>
                          <div className={cx.profile}>
                            <ProfilePicture
                              url={team?.profilePic}
                              fallback={team.name?.[0]?.toLocaleUpperCase()}
                            />
                            <div className={cx.teamDetails}>
                              <Typography
                                as="p"
                                variant="body-2"
                                fontWeight="semiBold"
                                className="key-member-card__header__user-info__user__name"
                              >
                                {team.name}
                              </Typography>
                              <Typography
                                as="p"
                                variant="caption"
                                fontWeight="regular"
                                className="key-member-card__header__user-info__user__role"
                              >
                                {team.role}
                              </Typography>
                            </div>
                          </div>
                          <div className={cx.addRemoveButton}>
                            <span onClick={() => setFieldValue('teamMemberIds', [...values.teamMemberIds, team.id])}><MdAdd /></span>
                            <span onClick={() => {
                              const filterTeam = values.teamMemberIds?.length > 0 ? JSON.parse(JSON.stringify(values.teamMemberIds?.filter((item: string) => team.id !== item))) : []
                              setFieldValue('teamMemberIds', filterTeam)
                            }}><MdRemove /></span>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                  {loading && <Loader />}
                </Row>
                <Row>
                  <Col md={3}>
                    <Button variant="primary" type="submit" size="small">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div >
    </div>
  )
}
