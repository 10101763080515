import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Button from 'src/components/button/Button';
import { Card, ConfirmModal, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { PlusIcon } from 'src/components/common/common.icons';
import { useAppSelector } from 'src/store/hooks';
import { useToggleOpenModal } from 'src/hooks';
import { getUuid, StorageUtils } from 'src/utils';
import { cancelCreateProgramModalId } from 'src/pages/createProgram/CreateProgram';

import './customApplicationForm.scss';
import {
  TApplicationFormSection,
  TCustomApplicationFormProps,
  TCustomApplicationSection
} from './customApplicationForm.types';
import CustomApplicationFormSection, {
  changeApplicationFormFieldTypeId
} from './customApplicationFormSection/CustomApplicationFormSection';
import createCustomApplicationFormSchema from './createCustomApplicationFormSchema';

import { TFieldType } from '../programDetails/programDetails.types';
import validateForm from '../../validateForm/validateForm';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { sortedIndexOf } from 'lodash';

const deleteApplicationFormSectionModalId = 'deleteApplicationFormSectionModal';
const deleteApplicationFormFieldModalId = 'deleteApplicationFormFieldModal';

const CustomApplicationForm = ({
  handleNext,
  handleDraft,
  applicationFormSections,
  programDetailsFormikValues,
  setApplicationFormSections
}: TCustomApplicationFormProps) => {
  // Hooks
  const modalId: string = useAppSelector((store) => store.common.modalId);
  const toggleOpenModal = useToggleOpenModal();

  // State
  const [selectedSectionForDeletion, setSelectedSectionForDeletion] = useState<string>('');
  const [selectedSectionFieldForDeletion, setSelectedSectionFieldForDeletion] = useState<{
    sectionId: string;
    fieldId: string;
  } | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [errorTrig, setErrorTrig] = useState<boolean>(false)
  // Constants
  const isDeleteApplicationFormSectionModalOpen = modalId === deleteApplicationFormSectionModalId;
  const isDeleteApplicationFormFieldModalOpen = modalId === deleteApplicationFormFieldModalId;

  const initialValues = {
    fullName: '',
    email: ''
  };

  useEffect(() => {
    // const storedFormData = localStorage.getItem('customFormData');
    const storedSectionsData = localStorage.getItem('applicationFormSections');
    // if (storedFormData) {
    //   setInitialValues(JSON.parse(storedFormData));
    // }

    if (JSON.parse(storedSectionsData)?.length > 0) {
      setApplicationFormSections(JSON.parse(storedSectionsData));
      StorageUtils.remove('applicationFormSections') 
    }
  }, [setApplicationFormSections]);

  // Section management
  const updateErrors = ({ errors }: { errors: Record<string, string> }) => {
    setErrors(errors);
  };

  const handleAddSection = () => {
    const newSection: TApplicationFormSection = {
      id: getUuid(),
      sectionTitle: '',
      sectionItems: []
    };

    setApplicationFormSections((prev) => prev.concat(newSection));
  };

  const toggleOpenDeleteSectionModal = () => {
    toggleOpenModal({ modalId: deleteApplicationFormSectionModalId });
  };

  const selectSectionForDeletion = ({ sectionId }: { sectionId: string }) => {
    setSelectedSectionForDeletion(sectionId);
    toggleOpenDeleteSectionModal();
  };

  const handleDeleteSection = () => {
    setApplicationFormSections((prev) => {
      try {
        const currentSections = JSON.parse(JSON.stringify(prev)) as TApplicationFormSection[];
        const selectedSectionIndex = currentSections.findIndex(
          (section: TApplicationFormSection) => section.id === selectedSectionForDeletion
        );

        if (selectedSectionIndex > -1) {
          currentSections.splice(selectedSectionIndex, 1);
        }

        return currentSections;
      } catch {
        return prev;
      }
    });
    setSelectedSectionForDeletion('');
    toggleOpenDeleteSectionModal();
  };

  const updateSectionTitle = ({ title, sectionId }: { title: string; sectionId: string }) => {
    setApplicationFormSections((prev) => {
      try {
        const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));
        const selectedSection = currentSections.find(
          (section) => section.id === sectionId
        ) as TApplicationFormSection;

        if (selectedSection) {
          selectedSection.sectionTitle = title;
        }

        return currentSections;
      } catch {
        return prev;
      }
    });
  };

  // Field management
  const handleToggleOpenChangeSectionFieldTypeModal = () => {
    toggleOpenModal({ modalId: changeApplicationFormFieldTypeId });
  };

  const toggleOpenDeleteFieldModal = () => {
    toggleOpenModal({ modalId: deleteApplicationFormFieldModalId });
  };

  const handleAddField = ({
    sectionId,
    fieldType
  }: {
    sectionId: string;
    fieldType: TFieldType;
  }) => {
    setApplicationFormSections((prev) => {
      const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

      const selectedSection = currentSections.find(
        (section) => section.id === sectionId
      ) as TApplicationFormSection;

      if (selectedSection) {
        const fields = selectedSection.sectionItems;
        const newId = getUuid();

        const newField: TCustomApplicationSection = {
          id: newId,
          label: '',
          type: fieldType,
          options: ['', ''],
          validations: { minChararactes: 1, maxCharacters: 3000, minOptionCount: 2 },
          required: false,
          response: '',
          files: [],
          placeholder: '',
          hasCharacterLimit: false
        };

        fields.push(newField);
      }

      return currentSections;
    });
  };

  const handleUpdateField = ({
    sectionId,
    fieldId,
    field
  }: {
    sectionId: string;
    fieldId: string;
    field: TCustomApplicationSection;
  }) => {
    setApplicationFormSections((prev) => {
      try {
        const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

        const selectedSection = currentSections.find(
          (section) => section.id === sectionId
        ) as TApplicationFormSection;

        const selectedFieldIndex: number = selectedSection?.sectionItems?.findIndex(
          (field: TCustomApplicationSection) => field?.id === fieldId
        );

        if (selectedFieldIndex > -1) {
          selectedSection.sectionItems[selectedFieldIndex] = field;
        }

        return currentSections;
      } catch {
        return prev;
      }
    });
  };

  const handleChangeFieldType = ({
    sectionId,
    fieldId,
    fieldType
  }: {
    sectionId: string;
    fieldId: string;
    fieldType: TFieldType;
  }) => {
    setApplicationFormSections((prev) => {
      try {
        const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

        const selectedSection = currentSections.find(
          (section) => section.id === sectionId
        ) as TApplicationFormSection;

        const selectedFieldIndex: number = selectedSection?.sectionItems?.findIndex(
          (field: TCustomApplicationSection) => field?.id === fieldId
        );

        if (selectedFieldIndex > -1) {
          const newField: TCustomApplicationSection = {
            id: fieldId,
            label: '',
            type: fieldType,
            options: ['', ''],
            validations: { minChararactes: 1, maxCharacters: 3000, minOptionCount: 2 },
            required: false,
            response: '',
            files: [],
            placeholder: '',
            hasCharacterLimit: false
          };

          selectedSection.sectionItems[selectedFieldIndex] = newField;
        }

        return currentSections;
      } catch {
        return prev;
      }
    });

    handleToggleOpenChangeSectionFieldTypeModal();
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      if (applicationFormSections?.length < 1) {
        setErrorTrig(true)
        return
      } else {
        setErrorTrig(false)
      }
      const customFieldValues: Record<string, string> = {};

      applicationFormSections.forEach((section) => {
        const sectionId: string = section?.id;
        const sectionItems = section?.sectionItems;

        customFieldValues[`sectionTitle-${sectionId}`] = section?.sectionTitle;

        sectionItems.forEach((sectionItem) => {
          const sectionItemId: string = sectionItem?.id;
          const sectionItemType: TFieldType = sectionItem?.type;
          const sectionItemLabel: string = sectionItem?.label;
          const sectionItemOptions: string[] = sectionItem?.options;
          const hasOptions: boolean =
            sectionItemType === 'dropdown' ||
            sectionItemType === 'multipleChoice' ||
            sectionItemType === 'singleChoice';

          customFieldValues[sectionItemId] = sectionItemLabel;

          if (hasOptions && sectionItemOptions?.length > 0) {
            sectionItemOptions.forEach((option, index) => {
              customFieldValues[`option-${index}-${sectionItemId}`] = option;
            });
          }
        });
      });

      const schema = createCustomApplicationFormSchema({ sectionFields: customFieldValues });

      const errors = await validateForm({
        values: customFieldValues,
        schema,
        updateErrors
      });
      localStorage.setItem('customFormData', JSON.stringify(values));
      // localStorage.setItem('applicationFormSections', JSON.stringify(applicationFormSections));
      const hasZeroErrors: boolean = errors?.length < 1;

      if (hasZeroErrors) {
        handleNext();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const handleOnDragEndSections = (result:any) => {
  //   console.log(result, 'result')

  //   if (!result.destination) return;

  //   setApplicationFormSections((prevSections) => {
  //     const items = Array.from(prevSections);
  //     const [movedItem] = items.splice(result.source.index, 1);
  //     items.splice(result.destination.index, 0, movedItem);
  //     return items;
  //   });
  // };  

  const selectedFieldForDeletion = ({
    sectionId,
    fieldId
  }: {
    sectionId: string;
    fieldId: string;
  }) => {
    setSelectedSectionFieldForDeletion({ sectionId, fieldId });
    toggleOpenDeleteFieldModal();
  };

  const handleDeleteField = () => {
    if (selectedSectionFieldForDeletion) {
      const { sectionId, fieldId } = selectedSectionFieldForDeletion;

      setApplicationFormSections((prev) => {
        try {
          const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

          const selectedSection = currentSections.find(
            (section) => section.id === sectionId
          ) as TApplicationFormSection;

          const selectedFieldIndex: number = selectedSection?.sectionItems?.findIndex(
            (field: TCustomApplicationSection) => field?.id === fieldId
          );

          if (selectedFieldIndex > -1) {
            selectedSection.sectionItems.splice(selectedFieldIndex, 1);
          }

          return currentSections;
        } catch {
          return prev;
        }
      });

      toggleOpenDeleteFieldModal();
    }
  };

  const handleToggleOpenCancelCreateProgramModal = () => {
    toggleOpenModal({ modalId: cancelCreateProgramModalId });
  };
  console.log(applicationFormSections, 'applicationFormSections')
  return (
    <div className="custom-application-form">
      {' '}
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => {
          return (
            <Form className="custom-application-form__form">
              <div className="custom-application-form__form__header">
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Create Application Form
                </Typography>
                <div className="custom-application-form__form__header__navigation">
                  <Button size="extraSmall" variant="primary" onClick={handleDraft}>
                    Save as Draft
                  </Button>
                  <Button
                    size="extraSmall"
                    variant="secondary"
                    onClick={handleToggleOpenCancelCreateProgramModal}
                  >
                    Discard & Exit
                  </Button>
                  <Button size="extraSmall" variant="primary" type="submit">
                    Preview
                  </Button>
                </div>
              </div>
              <Card className="custom-application-form__form__row">
                <Typography
                  as="p"
                  variant="body-1"
                  fontWeight="semiBold"
                  className="custom-application-form__form__row__heading"
                >
                  Personal Details
                </Typography>
                <div className="custom-application-form__form__row__fields">
                  <InputField
                    label="Full name"
                    id="fullName"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Full name"
                    type="text"
                    disabled
                    className="custom-application-form__form__row__fields__field"
                  />
                  <InputField
                    label="Email address"
                    id="email"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Email address"
                    type="text"
                    disabled
                    className="custom-application-form__form__row__fields__field"
                  />
                  <InputField
                    label="Mobile"
                    id="mobile"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="mobile"
                    type="text"
                    disabled
                    className="custom-application-form__form__row__fields__field"
                  />
                </div>
              </Card>
              {/* <DragDropContext onDragEnd={handleOnDragEndSections}>
  <Droppable droppableId="sections">
    {(provided:any) => (
      <div {...provided.droppableProps} ref={provided.innerRef}>
       
        {applicationFormSections.map((section: TApplicationFormSection, index) => {
          console.log(section.id.length, "section.id")
                return (
                  
                  <Draggable key={section.id} draggableId={String(section.id)} index={index}>
                  {(provided:any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                       <CustomApplicationFormSection
                    key={section.id} 
                    handleAddField={handleAddField}
                    handleUpdateField={handleUpdateField}
                    handleChangeFieldType={handleChangeFieldType}
                    selectSectionForDeletion={selectSectionForDeletion}
                    selectedFieldForDeletion={selectedFieldForDeletion}
                    updateSectionTitle={updateSectionTitle}
                    setApplicationFormSections = {setApplicationFormSections}
                    errors={errors}
                    {...section}
                  />
                    </div>
                  )}
                </Draggable>
                );
              })}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
</DragDropContext> */}
              {/* Sections start */}
              {applicationFormSections.map((section: TApplicationFormSection, index) => {
                return (
                  <CustomApplicationFormSection
                    key={index}
                    handleAddField={handleAddField}
                    handleUpdateField={handleUpdateField}
                    handleChangeFieldType={handleChangeFieldType}
                    selectSectionForDeletion={selectSectionForDeletion}
                    selectedFieldForDeletion={selectedFieldForDeletion}
                    updateSectionTitle={updateSectionTitle}
                    setApplicationFormSections={setApplicationFormSections}
                    errors={errors}
                    {...section}
                  />
                );
              })}
              {/* Sections end */}

              {/* Add Section */}
              <Button
                size="small"
                variant="secondary"
                startIcon={<PlusIcon />}
                className="custom-application-form__form__add-section"
                onClick={handleAddSection}
              >
                New Section
              </Button>
              {errorTrig && applicationFormSections?.length < 1 && <ErrorMessage className='mt-0' message={'please add atleast one section'} />}
            </Form>
          );
        }}
      </Formik>{' '}
      {isDeleteApplicationFormSectionModalOpen && (
        <ConfirmModal
          title="Are you sure you want to delete this section?"
          openConfirmModal={isDeleteApplicationFormSectionModalOpen}
          onClose={toggleOpenDeleteSectionModal}
          onSubmit={handleDeleteSection}
          submitText="Confirm"
          type="error"
        />
      )}
      {isDeleteApplicationFormFieldModalOpen && (
        <ConfirmModal
          title="Are you sure you want to delete this field?"
          openConfirmModal={isDeleteApplicationFormFieldModalOpen}
          onClose={toggleOpenDeleteFieldModal}
          onSubmit={handleDeleteField}
          submitText="Confirm"
          type="error"
        />
      )}
    </div>
  );
};

export default CustomApplicationForm;
