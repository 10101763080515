/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { Breadcrumbs, InfiniteScroll, TBreadcrumb, TTabItem, Tabs } from 'src/components/common';
import Header from 'src/components/header/Header';

import {
  ProgramActions,
  ProgramDetails,
  ProgramDiscussion,
  ProgramMembers,
  SubmittedApplications
} from 'src/components/company/programs/program';

import { TGetProgramRequest } from 'src/services/apiEndpoint.types.ts';

import {
  getProgram,
  incrementApplicantsPage,
  incrementProgramMembersPage,
  resetViewProgram
} from 'src/store/program/program.slice';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { TActionButton, TTabs } from './viewCohort.types';

import Evaluation from 'src/components/company/programs/program/Evaluation/Evaluation';
import cx from './viewCohort.module.scss';
import ResoucesData from 'src/components/company/programs/program/Resources/Resouces';
import { StorageUtils } from 'src/utils';
import CohortAction from './CohortActions';
import OnBoard from '../CohortsCompoents/OnBoard/OnBoard';
import Milestone from '../CohortsCompoents/Milestone/MileStone';
import Event from '../CohortsCompoents/Event/Event';
import Task from '../CohortsCompoents/Task/Task';
import { components } from 'react-select';
import Mentor from '../CohortsCompoents/Mentor/Mentor';
import Survey from '../CohortsCompoents/Survey/Survey';
import CohortRelationship from '../CohortsCompoents/CohortRelationship/CohortRelationship';
import { Col, Row } from 'react-bootstrap';
import CohortSidebar from '../Cohorts/CohortSidebar';
import CohortDashboard from '../CohortSidebarComponent/Dashboard/CohortDashboard';
import Teams from '../CohortSidebarComponent/Teams/Teams';
import InvestmentTracking from '../CohortSidebarComponent/InvestmentTracking/InvestmentTracking';
import Resources from '../CohortSidebarComponent/Resources/Resources';
import { getCohort, resetViewCohort } from 'src/store/cohort/cohort.slice';

const ViewCohort = () => {
  // Hooks
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const location = useLocation()
  const activeRoute = location?.search.split("tab=")[1] || 'Dashboard'
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading: boolean = useAppSelector((store: any) => store.common.loading);
  const { viewCohort }: any = useAppSelector((store: any) => store.cohort);
  const currentTab: any = paramValue({ paramName: 'tab' }) as any;
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const { loggedInUserType, loggedInCompanyId, authenticatedId } = getIdentities();
  const [selectedTab, setSelectedTab] = useState<string>('Dashboard')
  const handleViewCompany = () => {
    const viewSelfCompany: boolean =
      loggedInUserType === 'Company' && loggedInCompanyId === companyId;
    navigate(
      viewSelfCompany
        ? '/my-company?tab=cohorts'
        : `/view-company?id=${cohortId}&companyId=${companyId}&tab=cohorts`
    );
  };

  const breadCrumbs: TBreadcrumb[] = [
    { label: 'Company Profile', url: '', onClick: handleViewCompany },
    { label: 'Cohort Details', url: '' }
  ];

  // const loadMoreItems = () => {
  //   switch (currentTab) {
  //     case 'submittedApplications': {
  //       if (moreApplicantsLeft && !loading) {
  //         dispatch(incrementApplicantsPage(1));
  //       }
  //       break;
  //     }
  //     case 'programMembers': {
  //       if (moreProgramMembersLeft && !loading) {
  //         dispatch(incrementProgramMembersPage(1));
  //       }
  //       break;
  //     }
  //     default: {
  //       break;
  //     }
  //   }
  // };

  // const moreItemsLeft =
  //   currentTab === 'submittedApplications'
  //     ? moreApplicantsLeft
  //     : currentTab === 'programMembers'
  //       ? moreProgramMembersLeft
  //       : false;
  // const showFooter =
  //   currentTab === 'submittedApplications' || currentTab === 'programMembers' ? true : false;

  function handleClick(id: string) {
    setSelectedTab(id);
    navigate(`/view-cohort?id=${cohortId}&companyId=${companyId}&tab=${id}`)
  }
  const actionButtons: TActionButton[] = [
    { text: 'On-Board', id: "On-Board", onClick: handleClick },
    { text: 'Milestone', id: "Milestone", onClick: handleClick },
    // { text: 'Task', id: "Task", onClick: handleClick },
    { text: 'Mentor', id: "Mentor", onClick: handleClick },
    { text: 'Survey', id: "Survey", onClick: handleClick },
    { text: 'Event', id: "Event", onClick: handleClick }
  ];

  const tabs: TTabs[] = [
    { id: "Dashboard", component: <CohortDashboard handleClick={handleClick} actionButtons={actionButtons} /> },
    { id: "Teams", component: <Teams handleClick={handleClick} /> },
    { id: "Investment", component: <InvestmentTracking handleClick={handleClick} /> },
    { id: "Kpi", component: <InvestmentTracking handleClick={handleClick} /> },
    { id: "Resource", component: <Resources handleClick={handleClick} /> },
    { id: "On-Board", component: <OnBoard handleBack={handleBack} /> },
    { id: "Milestone", component: <Milestone handleBack={handleBack} /> },
    { id: "Task", component: <Task handleBack={handleBack} /> },
    { id: "Mentor", component: <Mentor handleBack={handleBack} /> },
    { id: "Survey", component: <Survey /> },
    { id: "Event", component: <Event /> },
    { id: "Relationship", component: <CohortRelationship handleBack={handleBack} handleClick={handleClick} /> }
  ]

  function handleBack() {
    // navigate(`/view-cohort?id=${companyId}&tab=Dashboard`);
    navigate(-1);
    // setSelectedTab('Dashboard')
  }
  const activeTab = tabs.find((tab: TTabs) => tab.id === selectedTab)

  useEffect(() => {
    if (activeRoute) {
      setSelectedTab(activeRoute)
    }
  }, [activeRoute])

  useEffect(() => {
    if (cohortId) {
      dispatch(getCohort({ cohortId }))
    }
    return () => {
      dispatch(resetViewCohort())
    }
  }, [cohortId])
  const sidebarArr: string[] = ['Dashboard', 'Investment', 'Teams', 'Kpi', 'Resource']
  const isSidebar = sidebarArr.includes(activeTab.id)
  return (
    <InfiniteScroll
      className={cx.viewCohort}
      onScrollEnd={() => { }}
      loading={loading}
      moreItemsLeft={false}
      showFooter={true}
      showLoader={false}
    >
      <Header />
      <div className={cx.body}>
        <Breadcrumbs breadcrumbs={breadCrumbs} />
        <CohortAction />
        <Row>
          {isSidebar && <Col md={3}>
            <CohortSidebar handleClick={handleClick} activeTab={selectedTab} />
          </Col>}
          <Col md={isSidebar ? 9 : 12}>
            {activeTab.component}
          </Col>
        </Row>
      </div>
    </InfiniteScroll>
  );
};

export default ViewCohort;
