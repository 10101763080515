import {
} from 'src/services/apiEndpoint.types.ts';

export type TInitialState = {
    cohorts?: any;
    moreCohortsLeft?: boolean;
    cohortPageNumber?: number;
    cohortPerPage?: number;
    viewCohort: any;
    searchedTeam: any[];
    cohortLoading?: boolean;
    cohortTeam?: any,
    viewCohortTeam?: any,
    viewMilestone?: any
};

export const initialState: TInitialState = {
    cohorts: [],
    moreCohortsLeft: false,
    cohortPageNumber: 0,
    cohortPerPage: 0,
    viewCohort: null,
    searchedTeam: [],
    cohortLoading: false,
    cohortTeam: [],
    viewCohortTeam: null,
    viewMilestone: null
};
