import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TProgramListRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { programList, resetPrograms } from 'src/store/program/program.slice';
import { Loader, Separator, Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';

import { TCohortsProps } from './cohortstypes';
import './cohorts.scss';

import CreateProgramCard from '../createProgramCard/CreateProgramCard';
import ProgramCard from '../programCard/ProgramCard';
import ProgramFilters from '../programFilters/ProgramFilters';
import { httpRequest } from 'src/services/commonApis';
import CohortCard from '../cohortCard/CohortCard';
import { changeCreateCohortModalState } from 'src/store/common/common.slice';
import CreateCohortModal from './CreateCohort';
import { cohortList, resetCohort } from 'src/store/cohort/cohort.slice';

const Cohorts = ({ companyId, isViewerProgramCreator = true }: TCohortsProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const isModalOpen: boolean = useAppSelector<boolean>((store: any) => store.common.isCohortOpen);
  const isLoading: boolean = useAppSelector<boolean>((store: any) => store.common.loading);
  const { cohorts }: any = useAppSelector<boolean>((store: any) => store.cohort);
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate();
  const { isUserKeyMember } = useAppSelector((store: any) => store.company.viewCompany);
  const dispatch = useAppDispatch();
  const selfCompany = useContext(SelfCompanyContext);

  // React state
  const { loggedInUserType, loggedInUserId } = getIdentities();
  function handelCreateCohort() {
    dispatch(changeCreateCohortModalState(true))
  }
  useEffect(() => {
    if (companyId) {
      dispatch(cohortList({ incubatorId: companyId }))
    }
    return () => {
      dispatch(resetCohort())
    }
  }, [dispatch, companyId])

  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      {isModalOpen && <CreateCohortModal companyId={companyId} />}
      <div className="company-programs">
        <div className="company-programs__header">
          <Typography variant="subHeading-2" fontWeight="semiBold">
            Cohorts
          </Typography>
          {loggedInUserType === 'Company' && selfCompany && isViewerProgramCreator && <div className="company-programs__header__buttons">
            <Button
              size="small"
              variant="primary"
              className="program-actions__actions__next"
              type="button"
              onClick={handelCreateCohort}
            >
              Create Cohort
            </Button>
          </div>}
        </div>
        <div className="company-programs__programs">
          {/* {loggedInUserType === 'Company' && selfCompany && <CreateProgramCard />} */}
          {cohorts?.map((cohort, index) => {
            return (
              <CohortCard {...cohort} key={index} companyId={companyId} />
            )
          })}
        </div>
      </div>
    </>
  );
};

export default Cohorts;
