import React, { useState } from 'react';
import cx from './milestone.module.scss';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';

interface TOnMilestoneProps {
    handleAction: (screen: string) => void;
}
export default function MileStone({ handleAction }: TOnMilestoneProps) {
    return (
        <div className={`${cx.onboard}`}>
            <div className={`${cx.titleHeader}`}>
                <h5 className={`${cx.title}`}>Cohort {'>'} Milestone</h5>
                <Button
                    size="small"
                    variant="primary"
                    className="program-actions__actions__next"
                    style={{ width: 'auto' }}
                    type="button"
                    onClick={() => handleAction('relationship')}
                >
                    Back
                </Button>
            </div>
            <div className='insideCard'>
                <Row>
                    <Col md={3}>
                        <div className={`${cx.inputFields}`}>
                            <InputField
                                required
                                label="Milestone"
                                id="milestone"
                                variant={componentSizeVariantsEnum.SMALL}
                                placeholder="Milestone"
                                type="text"
                            />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={`${cx.inputFields}`}>
                            <InputField
                                label="Start Date"
                                id="startDate"
                                variant={componentSizeVariantsEnum.SMALL}
                                placeholder="Start Date"
                                type="date"
                            />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={`${cx.inputFields}`}>
                            <InputField
                                label="End Date"
                                id="endDate"
                                variant={componentSizeVariantsEnum.SMALL}
                                placeholder="End Date"
                                type="date"
                            />
                        </div>
                    </Col>
                    <Col md={3}></Col>
                    <div className={cx.task}>
                        <Row>
                            <Col md={3}>
                                <div className={`${cx.inputFields}`}>
                                    <InputField
                                        label="Task"
                                        id="task"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="Task"
                                        type="text"
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={`${cx.inputFields}`}>
                                    <InputField
                                        label="Start Date"
                                        id="startDate"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="Start Date"
                                        type="date"
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={`${cx.inputFields}`}>
                                    <InputField
                                        label="End Date"
                                        id="endDate"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="End Date"
                                        type="date"
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={cx.addTask}>
                                    <span className={cx.addRemoveIcon}><MdAdd /></span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={cx.task}>
                        <Row>
                            <Col md={3}>
                                <div className={`${cx.inputFields}`}>
                                    <InputField
                                        label="Task"
                                        id="task"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="Task"
                                        type="text"
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={`${cx.inputFields}`}>
                                    <InputField
                                        label="Start Date"
                                        id="startDate"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="Start Date"
                                        type="date"
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={`${cx.inputFields}`}>
                                    <InputField
                                        label="End Date"
                                        id="endDate"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="End Date"
                                        type="date"
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={cx.addTask}>
                                    <span className={cx.addRemoveIcon}><MdRemove /></span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div >
            <div className={`${cx.addMilestone}`}>
                <MdAdd className={`${cx.addRemoveBtn}`} />
            </div>
        </div>
    )
}
