import * as Yup from 'yup';

const onBoardCohortValidationSchema = Yup.object().shape({
    cohortId: Yup.string().optional(),
    onboardingTo: Yup.string().required('this field is required'),
    cohortTeamsTitle: Yup.string().required('this field is required'),
    teamMemberIds: Yup.array()
        .of(Yup.string().required('Each team member ID must be a string'))
        .required('At least one team member is required')
        .min(1, 'At least one team member is required')
});

export default onBoardCohortValidationSchema;
