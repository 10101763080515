import { Accordion, Col, Dropdown } from 'react-bootstrap';
import { dotMenu, fileIcon } from 'src/assets/images';
import cx from '../resources.module.scss'
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Loader } from 'src/components/common';

export default function UploadedResourceBox({ resourceData }: any) {
  const dispatch = useDispatch();
  const [shareLoading, setShareLoading] = useState<boolean>(false)
  return (
    <Col md={3}>
      <div className={`${cx.uploadedBox} ${cx.document}`}>
        {shareLoading
          ? (
            <Loader />
          )
          : (
            <>
              <img src={fileIcon} className={cx.icon} alt="upload-resource" />
              <span title={resourceData?.url?.split('resourses/')?.[1]}>{resourceData?.title}</span>
              <div className={cx.dotDropdown}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic-6">
                    <img src={dotMenu} alt="dot" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <p className={cx.dotDropInside}>
                        <span>Download</span>
                      </p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          )}
      </div>
    </Col >
  )
}
