import { ReactNode } from 'react';
import { getMimeType, textEllipsis, StorageUtils } from 'src/utils';
import { DownloadIcon, FileIcon2, ImageIcon, VideoIcon } from 'src/components/common/common.icons';
import { Tooltip, Typography } from 'src/components/common';
import { colors } from 'src/constants';
import { TTextEllipsisResponse } from 'src/utils/textEllipsis/textEllipsis.types';
import { TFileCardProps } from './fileCard.types';
import './fileCard.scss';

const FileCard = ({ fileName, fileSize, key, removeFile, type = 'edit', ...rest }: TFileCardProps) => {
  // Utils
  const mimeType = getMimeType({ fileName });
  const renderFileName: TTextEllipsisResponse = textEllipsis({ charLimit: 29, text: fileName });
  const icons: Record<typeof mimeType, ReactNode> = {
    'image/jpeg': <ImageIcon />,
    'image/png': <ImageIcon />,
    'image/gif': <ImageIcon />,
    'image/bmp': <ImageIcon />,
    'image/webp': <ImageIcon />,
    'video/mp4': <VideoIcon />,
    'video/x-msvideo': <VideoIcon />
  };
  const draft: any = StorageUtils.get('ApplicationDraft');
  console.log(rest, 'restData');

  return (
    <div className="program-file-card" {...rest}>
      <div className="program-file-card__file">{icons[mimeType] || <FileIcon2 />}</div>
      <div className="program-file-card__text">
        <Tooltip text={fileName} conditionToDisplay={renderFileName?.hasEllipsis}>
          <Typography
            as="p"
            variant="caption"
            fontWeight="semiBold"
            style={{ color: colors.neutral[1000] }}
          >
            {renderFileName?.text}
          </Typography>
        </Tooltip>
        <Typography
          as="p"
          variant="caption"
          fontWeight="semiBold"
          style={{ color: colors.neutral[800] }}
        >
          {fileSize / 1000}kb
        </Typography>
      </div>
      <div className="program-file-card__actions">
        {draft ? <DownloadIcon /> : null}

        {type === 'edit' && <button
          type="button"
          className="remove-button"
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            removeFile()
          }}
          aria-label="Remove file"
        >
          X
        </button>}
      </div>
      {/* <div>
        <DownloadIcon />
        <button
          type="button"
          className="remove-button"
          onClick={() => onRemove && onRemove(key)}
          aria-label="Remove file"
        >
          X
        </button>
      </div> */}
    </div>
  );
};

export default FileCard;
