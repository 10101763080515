import { TMilestone } from './milestonetypes';

export const initialValues = {
    milestones: [
        {
            cohortId: '',
            userId: '',
            cohortMilestoneTitle: '',
            startDate: '',
            endDate: '',
            tasks: [{
                taskTitle: '',
                startDate: '',
                endDate: ''
            }]
        }
    ]
}

// export const initialValues = {
//     cohortId: '',
//     userId: '',
//     cohortMilestoneTitle: '',
//     startDate: '',
//     endDate: '',
//     tasks: [{
//         taskTitle: '',
//         startDate: '',
//         endDate: ''
//     }]
// }
