import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { componentSizeVariantsEnum } from 'src/constants';
import { InputField } from 'src/components/common/formFields';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import cx from './cohortRelationship.module.scss';

import { MdAdd } from 'react-icons/md';
import { UploadIcon } from 'src/components/common/common.icons';
import { useDispatch } from 'react-redux';
import { changeSubmittedReportModalState, changeTeamSubmittedReportModalState } from 'src/store/common/common.slice';
import { TActionButton } from 'src/components/company/programs/viewCohort/viewCohort.types';
import TrackStepper from 'src/components/common/TrackStepper/ActionStepper';
import SubmittedTeamReportSummeryModal from './SubmittedTeamReportSummeryModal';

interface TOnMilestoneProps {
    handleAction: (screen: string) => void;
}
export default function CohortRelationship({ handleAction: actionHandler }: TOnMilestoneProps) {
    const dispatch = useDispatch();
    const actionButtons: TActionButton[] = [
        { text: 'Milestone', id: "Milestone", onClick: handleAction },
        { text: 'Resource', id: "Resource", onClick: handleAction },
        { text: 'Submit Report', id: "Submit Report", onClick: handleAction }
    ];
    const steps: any = [
        { title: 'Milestone1' },
        { title: 'Milestone2' },
        { title: 'Milestone2' },
        { title: 'Milestone4' }
    ];
    const dragDropRef = useRef<HTMLDivElement>(null);
    const uploadImageRef = useRef<HTMLInputElement>(null);

    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };
    function handleAction(type: string) {
        if (type === 'Submit Report') {
            dispatch(changeTeamSubmittedReportModalState(true))
        } else {
            actionHandler(type)
        }
    }

    return (
        <>
            <SubmittedTeamReportSummeryModal />
            <div className={`${cx.relationship}`}>
                {/* <div className={`${cx.titleHeader}`}>
                    <h5 className={`${cx.title}`}>Team - X</h5>

                </div> */}
                <div className='insideCard'>
                    <Row>
                        <Col md={12} style={{ marginBottom: '8px' }}>
                            <Row>
                                <Col md={12}>
                                    <div className={cx.actionButtons}>
                                        {actionButtons?.map((button: TActionButton, i: number) => {
                                            return (
                                                <Button
                                                    size="small"
                                                    variant="primary"
                                                    className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                                                    type="button"
                                                    key={button.id}
                                                    onClick={() => button.onClick(button.text)}
                                                >
                                                    <span className={cx.addIcon}>{i === 2 && <MdAdd />}</span><span>{button.text} </span>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                </Col>
                                {/* <Col md={3}>
                                <div className={`${cx.percentage}`}>
                                    <span>Percentage completion</span>
                                    <span className={`${cx.highlight}`}>70%</span>
                                </div>
                            </Col> */}
                                <Col md={12}>
                                    <Row>
                                        <Col md={2}></Col>
                                        <Col md={4}>
                                            <div className={`${cx.fundAction}`}>
                                                <h5 className={`${cx.actionHeading}`}>Fund Alloted</h5>
                                                <InputField
                                                    required
                                                    label=""
                                                    style={{ width: '100px', padding: '10px' }}
                                                    id="fundAllocated"
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    placeholder="Fund Alloted"
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className={`${cx.fundAction}`}>
                                                <h5 className={`${cx.actionHeading}`}>Expense</h5>
                                                <InputField
                                                    required
                                                    label=""
                                                    style={{ width: '100px', textAlign: 'center' }}
                                                    id="expense"
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    placeholder="Expense"
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}></Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ marginBottom: '10px' }}>
                                            <div className={cx.stepper}>
                                                <TrackStepper steps={steps} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <div className={cx.details}>
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'T1'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"

                                                                >
                                                                    Team 1
                                                                </Typography>

                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'T2'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"

                                                                >
                                                                    Team 2
                                                                </Typography>

                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'T3'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"

                                                                >
                                                                    Team 3
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'M2'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"
                                                                >
                                                                    Mentor 1
                                                                </Typography>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'M2'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"
                                                                >
                                                                    Mentor 2
                                                                </Typography>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={`${cx.teamCard}`}>
                                                            <ProfilePicture
                                                                url={''}
                                                                fallback={'M13'}
                                                            />
                                                            <div>
                                                                <Typography
                                                                    as="p"
                                                                    variant="body-2"
                                                                    fontWeight="semiBold"
                                                                >
                                                                    Mentor 3
                                                                </Typography>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <div className='insideCard mt-3'>
                                            <ul className={cx.milestonelist}>
                                                <li>
                                                    <div className={'milestoneBox'}>
                                                        <span className={cx.mileStoneHeading}>Milestone 1</span>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="completion"
                                                                style={{ padding: '5px' }}
                                                                className='milestonePercentageCompletion'
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="% completion"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="comments"
                                                                style={{ padding: '5px', textAlign: 'center' }}
                                                                className={`${cx.comments}`}
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="Comments"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <ul className={cx.nestedList}>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><hr /></li>
                                                <li>
                                                    <div className={'milestoneBox'}>
                                                        <span className={cx.mileStoneHeading}>Milestone 2</span>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="completion"
                                                                style={{ padding: '5px' }}
                                                                className='milestonePercentageCompletion'
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="% completion"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className={`${cx.inputFields}`}>
                                                            <InputField
                                                                label=""
                                                                id="comments"
                                                                style={{ padding: '5px', textAlign: 'center' }}
                                                                className={`${cx.comments}`}
                                                                variant={componentSizeVariantsEnum.SMALL}
                                                                placeholder="Comments"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <ul className={cx.nestedList}>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className={cx.task}>
                                                            <div className={'milestoneBox'}>
                                                                <span className={cx.taskHeading}>Complete every demo detail that is in this milestone1</span>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="completion"
                                                                        style={{ padding: '5px' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="% completion"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <InputField
                                                                        label=""
                                                                        id="comments"
                                                                        style={{ padding: '5px', textAlign: 'center' }}
                                                                        className={`${cx.comments}`}
                                                                        variant={componentSizeVariantsEnum.SMALL}
                                                                        placeholder="Comments"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className={`${cx.inputFields}`}>
                                                                    <>
                                                                        <InputField
                                                                            label=""
                                                                            id='file'
                                                                            hidden
                                                                            variant={componentSizeVariantsEnum.SMALL}
                                                                            placeholder=""
                                                                            type="text"
                                                                        />

                                                                        <div
                                                                            draggable
                                                                            ref={dragDropRef}
                                                                            onDragOver={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <span className="" onClick={openImageFileManager}>
                                                                                <UploadIcon style={{ marginLeft: '15px' }} />
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    value={''}
                                                                                    ref={uploadImageRef}
                                                                                    accept=".jpg,.jpeg,.png,"
                                                                                    className="select-post-image"

                                                                                // onChange={(e) => {
                                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                                // }}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div >
            </div>
        </>
    )
}
