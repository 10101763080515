import { AppDispatch } from './../store';
import { httpRequest } from '../../services/commonApis';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
} from 'src/services/apiEndpoint.types.ts';
import { errorMessages } from 'src/constants';

import { initialState } from './cohort.slice.initialState';
import { openPopup, startLoading, stopLoading } from '../common/common.slice';
import { TSearchCohort } from './cohort.type';
export const cohortSlice = createSlice({
  name: 'cohort',
  initialState,
  reducers: {
    // cohorts
    cohortLoadingStart: (state: any) => {
      state.cohortLoading = true
    },
    cohortLoadingEnd: (state: any) => {
      state.cohortLoading = false
    },
    cohortListSuccess: (state, action: PayloadAction<any>) => {
      const currentCohort: any[] = JSON.parse(JSON.stringify(action.payload)) || [];
      state.cohorts = currentCohort;
      // state.moreCohortsLeft = currentPage < totalPages;
      // state.cohortPageNumber = currentPage;
      // state.cohortPerPage = itemsPerPage;
    },
    cohortListError: () => { },
    incrementProgramsPage: (state, action: PayloadAction<number>) => {
      state.cohortPageNumber = state.cohortPageNumber + action.payload;
    },
    resetCohort: (state) => {
      state.cohortPageNumber = 1;
      state.moreCohortsLeft = true;
      state.cohorts = [];
    },

    // View program
    getCohortSuccess: (state, action: PayloadAction<any>) => {
      const cohortData: any = action.payload;

      state.viewCohort = cohortData;
    },
    getCohortError: () => { },
    resetViewCohort: (state) => {
      state.viewCohort = initialState.viewCohort;
    },
    searchedTeamList: (state, action) => {
      state.searchedTeam = action.payload
    },
    resetTeamSearch: (state) => {
      state.searchedTeam = []
    },
    getCohortTeamList: (state, action: PayloadAction<any>) => {
      const teamData: any = action.payload;
      state.cohortTeam = teamData;
    },
    getCohortTeamSuccess: (state, action: PayloadAction<any>) => {
      const teamData: any = action.payload;
      state.viewCohortTeam = teamData;
    },
    getCohortMilestoneSuccess: (state, action: PayloadAction<any>) => {
      const milestone: any = action.payload;
      state.viewMilestone = milestone;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  cohortListSuccess,
  cohortListError,
  incrementProgramsPage,
  resetCohort,
  getCohortSuccess,
  resetViewCohort,
  searchedTeamList,
  resetTeamSearch,
  cohortLoadingStart,
  cohortLoadingEnd,
  getCohortTeamList,
  getCohortTeamSuccess
} = cohortSlice.actions;

export default cohortSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const cohortList = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  const path = `cohort/get?incubatorId=${payload?.incubatorId}`
  const response = await httpRequest(path, 'GET', null, 'json', 'program')
  if (response?.status) {
    dispatch(cohortListSuccess(response?.data));
  } else {
    dispatch(cohortListSuccess([]));
  }
  dispatch(stopLoading());
};

export const getCohort = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  const path = `cohort/get?id=${payload?.cohortId}`
  const response = await httpRequest(path, 'GET', null, 'json', 'program')
  if (response?.status) {
    dispatch(getCohortSuccess(response?.data[0] || null));
  } else {
    dispatch(getCohortSuccess(null));
  }
  dispatch(stopLoading());
}
export const cohortTeamSearch = (payload: any) => async (dispatch: any) => {
  dispatch(cohortLoadingStart());
  const { key, page, pageSize, companyId } = payload
  const path = `search-users?key=${key}&page=${page}&pageSize=${pageSize}&companyId=${companyId}`
  const response = await httpRequest(path, 'GET', null, 'json', 'company')
  if (response?.status) {
    dispatch(searchedTeamList(response?.data[0] || []));
  } else {
    dispatch(searchedTeamList([]));
  }
  dispatch(cohortLoadingEnd());
}
// export const createCohortTeam = (payload: any) => async (dispatch: AppDispatch) => {
//   dispatch(startLoading());
//   const path = `cohort/get?id=${payload?.cohortId}`
//   const response = await httpRequest(path, 'GET', null, 'json', 'program')
//   if (response?.status) {
//     dispatch(getCohortSuccess(response?.data[0] || null));
//   } else {
//     dispatch(getCohortSuccess(null));
//   }
//   dispatch(stopLoading());
// } 
export const cohortTeamList = (payload: any) => async (dispatch: AppDispatch) => {
  const { cohortId } = payload
  dispatch(startLoading());
  const path = `cohort/team/get?cohortId=${cohortId}`
  const response = await httpRequest(path, 'GET', null, 'json', 'program')
  if (response?.status) {
    dispatch(getCohortTeamList(response?.data));
  } else {
    dispatch(getCohortTeamList([]));
  }
  dispatch(stopLoading());
};
export const viewcohortTeam = (payload: any) => async (dispatch: AppDispatch) => {
  const { cohortId, teamId } = payload
  dispatch(startLoading());
  const path = `cohort/team/get?cohortId=${cohortId}&id=${teamId}`
  const response = await httpRequest(path, 'GET', null, 'json', 'program')
  if (response?.status) {
    dispatch(getCohortTeamSuccess(response?.data));
  } else {
    dispatch(getCohortTeamSuccess([]));
  }
  dispatch(stopLoading());
};
export const createCohortMilestone = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  const { ...payloadData } = payload
  const path = 'cohort/milestone/create'
  const response = await httpRequest(path, 'POST', payloadData, 'json', 'program')
  if (response?.status) {
    dispatch(
      openPopup({
        message: response?.message,
        popupType: 'success'
      })
    );
  } else {
    dispatch(
      openPopup({
        message: response?.message,
        popupType: 'error'
      })
    );
  }
  dispatch(stopLoading());
};
export const getMilestone = (payload: any) => async (dispatch: any) => {
  const { cohortId, id, userId } = payload
  dispatch(startLoading());
  const path = `cohort/milestone/get?cohortId=${cohortId}&id=${id}&userId=${userId}`
  const response = await httpRequest(path, 'GET', null, 'json', 'program')
  if (response?.status) {
    dispatch(getCohortTeamSuccess(response?.data[0] || null));
  } else {
    dispatch(getCohortTeamSuccess(null));
  }
  dispatch(stopLoading());
}
