import React from 'react';
import cx from './investmentTracking.module.scss';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import { Col, Row } from 'react-bootstrap';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { MdAdd } from 'react-icons/md';

export default function InvestmentTracking({ handleClick, handleBack }: any) {
  return (
    <div className={`${cx.investementTracking}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Investment Tracking</h5>
        {/* <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button> */}
      </div>
      <Row>
        <Col md={12}>
          <div className='insideCard transparent'>
            <div className={cx.itemContainer}>
              <div className={cx.itemLabel}>
                <Row>
                  <Col md={4} lg={3}>
                    <label className={`${cx.label}`}>Teams</label>
                  </Col>
                  <Col md={2}>
                    <label className={`${cx.label}`}>Fund Alloted</label>
                  </Col>
                  <Col md={2}>
                    <label className={`${cx.label}`}>Expense</label>
                  </Col>
                  <Col md={3}>
                    <label className={`${cx.label}`}>Additional Allotment</label>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md={4} lg={3}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'A'}
                    />
                    <div>
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"

                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"

                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>

                  </div>
                </Col>
                <Col md={2}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label=""
                      style={{ width: '100%' }}
                      id="fund alloted"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="0"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label=""
                      id="Expense"
                      style={{ width: '100%' }}
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="0"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label=""
                      style={{ width: '100%' }}
                      id="Expense"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="0"
                      type="text"
                    />
                    <Button
                      size="small"
                      variant="primary"
                      className="program-actions__actions__next"
                      style={{ width: 'auto' }}
                      type="button"
                      onClick={handleBack}
                    >
                      <MdAdd className={`${cx.addIcon}`} />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} lg={3}>
                  <div className={`${cx.teamCard}`}>
                    <ProfilePicture
                      url={''}
                      fallback={'A'}
                    />
                    <div>
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"

                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"

                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>

                  </div>
                </Col>
                <Col md={2}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label=""
                      style={{ width: '100%' }}
                      id="fund alloted"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="0"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label=""
                      style={{ width: '100%' }}
                      id="Expense"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="0"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className={`${cx.inputFields}`}>
                    <InputField
                      label=""
                      style={{ width: '100%' }}
                      id="Expense"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="0"
                      type="text"
                    />
                    <Button
                      size="small"
                      variant="primary"
                      className="program-actions__actions__next"
                      style={{ width: 'auto' }}
                      type="button"
                      onClick={handleBack}
                    >
                      <MdAdd className={`${cx.addIcon}`} />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
