import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import './actionStepper.scss'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 8,
        left: 'calc(-50% + 4px)',
        right: 'calc(50% + 4px)'
        // border: '2px solid #fff',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: '#fa4616'
            // border: '1px solid #fff',
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background:
                '#fa4616'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 5,
        border: 0,
        backgroundColor: '#e1d1c9',
        borderRadius: 1,
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800]
        })
    }
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[700]
    }),
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                background:
                    '#fa4616',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
                border: '3px solid #fff',
                outline: '1px solid #e1d1c9'
            }
        },
        {
            props: ({ ownerState }) => ownerState.completed,
            style: {
                background:
                    '#fa4616'
            }
        }
    ]
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    //   const icons: { [index: string]: React.ReactElement<any> } = {
    //     1: <SettingsIcon />,
    //     2: <GroupAddIcon />,
    //     3: <VideoLabelIcon />,
    //   };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {/* {[String(props.icon)]} */}
        </ColorlibStepIconRoot>
    );
}

export default function TrackStepper({ steps, activeStep = 0 }: { steps: { title?: string, subTitle?: string }[], activeStep?:number }) {
    return (
        <Stack sx={{ width: '100%' }} spacing={4} className='stepper_bar'>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((item, i: number) => {
                    return (
                        <Step key={i}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <StepData {...item} />
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Stack>
    );
}

function StepData({ title, subTitle }: any) {
    return (
        <div className='stepperTitle_Box'>
            <h5>{title}</h5>
            {subTitle && <h6>{subTitle}</h6>}
        </div>
    )
}
