import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Modal } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import { changeCreateCohortModalState, openPopup } from 'src/store/common/common.slice';
import { useGetPlaces } from 'src/hooks';

import './crateCohort.scss';
import createCohortValidationSchema from './createCohortValidationSchema';
import { useNavigate } from 'react-router-dom';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import { httpRequest } from 'src/services/commonApis';
import { cohortList } from 'src/store/cohort/cohort.slice';

export type TCreateCohortValues = {
    incubatorId: string,
    cohortTitle: string,
    startDate: string,
    endDate: string,
    programObjective: string,
    templates?: {
        investmentTracking?: boolean,
        kpiTracking?: boolean
    }
};

const CreateCohortModal = ({ companyId }: { companyId: string }) => {
    const loading: boolean = useAppSelector<boolean>((store: any) => store.common.loading);
    const isModalOpen: boolean = useAppSelector<boolean>((store: any) => store.common.isCohortOpen);
    const navigate = useNavigate();

    // const myCompany: TGetCompany = useAppSelector<TGetCompany>((store) => store.company.myCompany);

    const initialValues: TCreateCohortValues = {
        incubatorId: '',
        cohortTitle: '',
        startDate: '',
        endDate: '',
        programObjective: '',
        templates: {
            investmentTracking: false,
            kpiTracking: false
        }
    };

    // Hooks
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: TCreateCohortValues) => {
        const {
            cohortTitle,
            startDate,
            endDate,
            programObjective,
            templates
        } = values;

        const payload: TCreateCohortValues = {
            incubatorId: companyId,
            cohortTitle,
            startDate,
            endDate,
            programObjective,
            templates
        };
        const response = await httpRequest('cohort/create', 'POST', payload, 'json', 'program');
        if (response?.status) {
            dispatch(cohortList({ incubatorId: companyId }))
            dispatch(
                openPopup({
                    popupMessage: response.message,
                    popupType: 'success'
                })
            );
            navigate(`/view-cohort?id=${response?.data?.id}&companyId=${companyId}&tab=Dashboard`);
            closeModal()
        } else {
            dispatch(
                openPopup({
                    popupMessage: response.message,
                    popupType: 'error'
                })
            );
        }
    };

    const closeModal = () => {
        dispatch(changeCreateCohortModalState(false));
    };

    return (
        <Modal
            isModalOpen={isModalOpen}
            onModalClose={closeModal}
            title="Create Cohort"
            className="edit-cohort-modal"
        >
            <div className="edit-cohort">
                {/* Form starts */}
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={createCohortValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors, touched }) => {
                        return (
                            <Form className="edit-cohort__form" noValidate>
                                <div className='edit-cohort__form__container-cohort'>
                                    <InputField
                                        required
                                        label="Cohort Title"
                                        id="cohortTitle"
                                        value={values?.cohortTitle}
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="Cohort Title"
                                        type="text"
                                    />
                                    <InputField
                                        required
                                        label="Start Date"
                                        id="startDate"
                                        value={values?.startDate}
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder=""
                                        type="date"
                                    />

                                    <InputField
                                        required
                                        label="End Date"
                                        value={values?.endDate}
                                        id="endDate"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder=""
                                        type="date"
                                    />
                                    <h5 className='typography__caption typography__semiBold'>Program Objective</h5>
                                    <TextArea id='programObjective' placeholder='Program Objective'
                                        value={values?.programObjective} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('programObjective', e.target.value)}
                                        textAreaStyle={{ width: '100%' }}
                                    />
                                    <h5 className='typography__caption typography__semiBold'>Add templates</h5>
                                    <Row style={{ marginBottom: '16px' }}>
                                        <Col md={3}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={values?.templates?.investmentTracking}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('templates', { ...values?.templates, investmentTracking: e.target.checked })}
                                                />
                                                <h5 className='typography__caption typography__semiBold'>Investment Tracking</h5>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={values?.templates?.kpiTracking}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('templates', { ...values?.templates, kpiTracking: e.target.checked })}
                                                />
                                                <h5 className='typography__caption typography__semiBold'>KPI Tracking</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="edit-cohort__form__buttons">
                                    <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                                        Close
                                    </Button>

                                    <Button variant="primary" type="submit" size="small" loading={loading} >
                                        Create Cohort
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik >
            </div >
        </Modal >
    );
};

export default CreateCohortModal;
